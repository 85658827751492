import AppText from "../../global/AppText";
import AppInput from "../../global/inputs/AppInput";
import Column from "../../global/layouts/Column";
import MainLayout from "../../global/layouts/MainLayout";
import AppButton from "../../global/buttons/AppButton";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AppErrorMessage from "../../global/AppErrorMessage";
import { useState } from "react";

const LoginPage = () => {
  const validationSchema = Yup.object().shape({
    username: Yup.string()
                  .required('Username is required'),
    password: Yup.string()
                  .required('Password is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const onSubmit = (data: any) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
  };

  return (
    <MainLayout transparent style={{justifyContent: 'center', display: 'flex'}}>
      <Column style={{flex: 1, maxWidth: '600px'}}>
      <form style={ { display: 'flex', flexDirection: 'column'} } onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <AppText xl bold style={{marginBottom: 8}}>Admin Login</AppText>
        <AppText sm style={{marginBottom: 8}}>Username</AppText>
        <AppInput {...register('username')} error={!!errors.username}/>
        <AppErrorMessage name='username' errors={errors}/>
        <AppText sm style={{marginBottom: 8, marginTop: 22}}>Password</AppText>
        <AppInput placeholder="******" type="password" {...register('password')} error={!!errors.password}/>
        <AppErrorMessage name='password' errors={errors}/>
        <AppButton loading={isSubmitting} style={{marginTop: 24}} type='submit' text='Login' />
      </form>
      </Column>
    </MainLayout>
  );
}
export default LoginPage;