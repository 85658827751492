import AppText from "../../global/AppText";
import AppInput from "../../global/inputs/AppInput";
import Column from "../../global/layouts/Column";
import MainLayout from "../../global/layouts/MainLayout";
import otpCode from '../../../assets/otpCode.png';
import Row from "../../global/layouts/Row";
import AppButton from "../../global/buttons/AppButton";
import { useNavigate, useParams} from 'react-router-dom';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppErrorMessage from "../../global/AppErrorMessage";
import { FadeLoader } from 'react-spinners';
import { Theme } from "../../../styles/theme";
import { InviteService } from '@lookahead/core';

const InvitePage = () => {
  const { token } = useParams();
  const [ isReady, setIsReady ] = useState(false);
  const navigate = useNavigate();
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ hasValidInvite, setHasValidInvite ] = useState(false);
  const [ userNameInput, setUserNameInput ] = useState<'email' | 'phone'>('phone');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
                  .required('First Name is required'),
    lastName: Yup.string()
                  .required('Last Name is required'),
    position: Yup.string()
                  .required('Position is required'),
    password: Yup.string()
                  .required('Password is required')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    "Must Contain 8 Characters and at least 1 uppercase and lowercase letter, 1 number and 1 special character (!@#$%^&*)"
                  ),
    passwordConfirm: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    email: Yup.string().nullable(),
    phone: Yup.string().nullable(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data: any) => {
    if (!token || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    InviteService.acceptInvite(token, data.firstName, data.lastName, data.position, data.password, data.phone, data.email)
      .subscribe({
        next: () => { setIsSubmitting(false); navigate('/invite-accept', {replace: true}); },
        error: () => { setIsSubmitting(false) },
      });
  };

  useEffect(() => {
    if (token === undefined) {
      setIsReady(true);
      return;
    }
    InviteService.getInvite(token).subscribe({
      next: invite => {
        setUserNameInput(invite.phone ? 'phone' : 'email');
        reset({
          firstName: invite.user?.first_name,
          lastName: invite.user?.last_name,
          position: invite.user?.position,
          password: '',
          passwordConfirm: '',
          email: invite.email,
          phone: invite.phone
        });
        setHasValidInvite(true);
        setIsReady(true);
      },
      error: _ => { setIsReady(true); }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <MainLayout transparent>
    <Column>
        {!isReady &&
          <Column justifyContent="center" alignItems="center">
            <Row justifyContent="center">
              <img src={otpCode} alt="Welcome to Lookahead" style={{maxWidth: 345, marginBottom: 24}}/>
            </Row>
            <FadeLoader />
            <AppText>Getting Invite...</AppText>
          </Column>
        }
        {isReady && !hasValidInvite &&
        <>
          <Row justifyContent="center">
            <img src={otpCode} alt="Welcome to Lookahead" style={{maxWidth: 345, marginBottom: 24}}/>
          </Row>
          <Column style={{backgroundColor: Theme.colors.red50, padding: 10, borderRadius: 5, border: `1px solid ${Theme.colors.red100}`}}>
            <AppText lg style={{marginBottom: 8}}>Invitation Expired</AppText>
            <AppText sm>Lookahead Invitation has expired. Please contact a project administrator to resend the invitation.</AppText>
          </Column>
        </>
        }
        { isReady && hasValidInvite &&
        <>
        <AppText xl bold style={{marginBottom: 8}}>Welcome!</AppText>
        <AppText sm style={{marginBottom: 8}}>You have been added to LookAhead! Please enter your user information below.</AppText>
        <Row justifyContent="center">
          <img src={otpCode} alt="Welcome to Lookahead" style={{maxWidth: 345, marginBottom: 24}}/>
        </Row>
        <form style={ { display: 'flex', flexDirection: 'column'} } onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
          <AppText sm style={{marginBottom: 8}}>First Name</AppText>
          <AppInput placeholder="First" {...register('firstName')} error={!!errors.firstName}/>
          <AppErrorMessage name='firstName' errors={errors}/>
          <AppText sm style={{marginTop: 13, marginBottom: 8}}>Last Name</AppText>
          <AppInput placeholder="Last" {...register('lastName')} error={!!errors.lastName}/>
          <AppErrorMessage name='lastName' errors={errors}/>
          {userNameInput === 'phone' &&
            <>
              <AppText sm style={{marginTop: 13, marginBottom: 8}}>Phone</AppText>
              <AppInput disabled={true} placeholder="555-555-5555" {...register('phone')} error={!!errors.phone} />
              <AppErrorMessage name='phone' errors={errors}/>
            </>
          }
          {userNameInput === 'email' &&
            <>
              <AppText sm style={{marginTop: 13, marginBottom: 8}}>Email</AppText>
              <AppInput disabled={true} placeholder="ex. jsmith@mail.com" {...register('email')} error={!!errors.email} />
              <AppErrorMessage name='email' errors={errors}/>
            </>
          }
          <AppText sm style={{marginTop: 13, marginBottom: 8}}>Position</AppText>
          <AppInput placeholder="ex. Foreman" {...register('position')} error={!!errors.position}/>
          <AppErrorMessage name='position' errors={errors}/>
          <AppText sm style={{marginTop: 13, marginBottom: 8}}>Password</AppText>
          <AppInput placeholder="******" type="password"  {...register('password')} error={!!errors.password}/>
          <AppErrorMessage name='password' errors={errors}/>
          <AppText sm style={{marginTop: 13, marginBottom: 8}}>Confirm Password</AppText>
          <AppInput placeholder="******" type="password" {...register('passwordConfirm')} error={!!errors.passwordConfirm}/>
          <AppErrorMessage name='passwordConfirm' errors={errors}/>
          <AppButton loading={isSubmitting} style={{marginTop: 24}}type='submit' text='Accept Invite' />
        </form>
        </>
        }
    </Column>
  </MainLayout>
  )
}
export default InvitePage;