export var Permission;
(function (Permission) {
    Permission["CREATE_PUBLISHED_ACTIVITY"] = "CREATE_PUBLISHED_ACTIVITY";
    Permission["EDIT_PUBLISHED_ACTIVITY_OWNER"] = "EDIT_PUBLISHED_ACTIVITY_OWNER";
    Permission["EDIT_PUBLISHED_ACTIVITY_NON_OWNER"] = "EDIT_PUBLISHED_ACTIVITY_NON_OWNER";
    Permission["DELETE_PUBLISHED_ACTIVITY_OWNER"] = "DELETE_PUBLISHED_ACTIVITY_OWNER";
    Permission["DELETE_PUBLISHED_ACTIVITY_NON_OWNER"] = "DELETE_PUBLISHED_ACTIVITY_NON_OWNER";
    Permission["CREATE_UNPUBLISHED_ACTIVITY"] = "CREATE_UNPUBLISHED_ACTIVITY";
    Permission["EDIT_UNPUBLISHED_ACTIVITY_OWNER"] = "EDIT_UNPUBLISHED_ACTIVITY_OWNER";
    Permission["EDIT_UNPUBLISHED_ACTIVITY_NON_OWNER"] = "EDIT_UNPUBLISHED_ACTIVITY_NON_OWNER";
    Permission["DELETE_UNPUBLISHED_ACTIVITY_OWNER"] = "DELETE_UNPUBLISHED_ACTIVITY_OWNER";
    Permission["DELETE_UNPUBLISHED_ACTIVITY_NON_OWNER"] = "DELETE_UNPUBLISHED_ACTIVITY_NON_OWNER";
    Permission["RESPOND_TO_PUBLISHED_ACTIVITY"] = "RESPOND_TO_PUBLISHED_ACTIVITY";
    Permission["OVERRIDE_RESPONSE_TO_PUBLISHED_ACTIVITY"] = "OVERRIDE_RESPONSE_TO_PUBLISHED_ACTIVITY";
    Permission["RESPOND_TO_UNPUBLISHED_ACTIVITY"] = "RESPOND_TO_UNPUBLISHED_ACTIVITY";
    Permission["OVERRIDE_RESPONSE_TO_UNPUBLISHED_ACTIVITY"] = "OVERRIDE_RESPONSE_TO_UNPUBLISHED_ACTIVITY";
    Permission["VIEW_ACTIVITIES_IN_COMPANY"] = "VIEW_ACTIVITIES_IN_COMPANY";
    Permission["VIEW_ALL_ACTIVITIES"] = "VIEW_ALL_ACTIVITIES";
    Permission["ADD_COMMENT_TO_PUBLISHED_ACTIVITY"] = "ADD_COMMENT_TO_PUBLISHED_ACTIVITY";
    Permission["ADD_COMMENT_TO_UNPUBLISHED_ACTIVITY"] = "ADD_COMMENT_TO_UNPUBLISHED_ACTIVITY";
    Permission["SEND_NOTIFICATION"] = "SEND_NOTIFICATION";
    Permission["CREATE_PUBLISHED_SCHEDULE"] = "CREATE_PUBLISHED_SCHEDULE";
    Permission["CREATE_UNPUBLISHED_SCHEDULE"] = "CREATE_UNPUBLISHED_SCHEDULE";
    Permission["UNPUBLISHED_SCHEDULE_OWNER"] = "UNPUBLISHED_SCHEDULE_OWNER";
    Permission["UNPUBLISHED_SCHEDULE_NON_OWNER"] = "UNPUBLISHED_SCHEDULE_NON_OWNER";
    Permission["DELETE_UNPUBLISHED_SCHEDULE_OWNER"] = "DELETE_UNPUBLISHED_SCHEDULE_OWNER";
    Permission["DELETE_UNPUBLISHED_SCHEDULE_NON_OWNER"] = "DELETE_UNPUBLISHED_SCHEDULE_NON_OWNER";
    Permission["SHARE_SCHEDULES"] = "SHARE_SCHEDULES";
    Permission["VIEW_PROJECT_INSIGHTS"] = "VIEW_PROJECT_INSIGHTS";
    Permission["VIEW_COMPANY_INSIGHTS_IN_COMPANY"] = "VIEW_COMPANY_INSIGHTS_IN_COMPANY";
    Permission["VIEW_COMPANY_INSIGHTS_ALL"] = "VIEW_COMPANY_INSIGHTS_ALL";
    Permission["CREATE_REPORT"] = "CREATE_REPORT";
    Permission["CREATE_PRIVATE_ANNOUNCEMENT"] = "CREATE_PRIVATE_ANNOUNCEMENT";
    Permission["ADD_COMMENT_TO_PRIVATE_ANNOUNCEMENT"] = "ADD_COMMENT_TO_PRIVATE_ANNOUNCEMENT";
    Permission["CREATE_PUBLIC_ANNOUNCEMENT"] = "CREATE_PUBLIC_ANNOUNCEMENT";
    Permission["ADD_COMMENT_TO_PUBLIC_ANNOUNCEMENT"] = "ADD_COMMENT_TO_PUBLIC_ANNOUNCEMENT";
    Permission["ADD_USER"] = "ADD_USER";
    Permission["DELETE_USER"] = "DELETE_USER";
    Permission["EDIT_USER_INFO_OWNER"] = "EDIT_USER_INFO_OWNER";
    Permission["EDIT_USER_INFO_NON_OWNER"] = "EDIT_USER_INFO_NON_OWNER";
    Permission["EDIT_COMPANY_INFO"] = "EDIT_COMPANY_INFO";
    Permission["ADD_COMPANY"] = "ADD_COMPANY";
    Permission["CREATE_RAIN_DELAY"] = "CREATE_RAIN_DELAY";
    Permission["ADD_HOLIDAY"] = "ADD_HOLIDAY";
    Permission["DELETE_RAIN_DELAY"] = "DELETE_RAIN_DELAY";
    Permission["DELETE_HOLIDAY"] = "DELETE_HOLIDAY";
    Permission["DELETE_ANNOUNCEMENT_NON_OWNER"] = "DELETE_ANNOUNCEMENT_NON_OWNER";
})(Permission = Permission || (Permission = {}));
;
