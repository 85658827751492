export var Day;
(function (Day) {
    Day["SUNDAY"] = "SUNDAY";
    Day["MONDAY"] = "MONDAY";
    Day["TUESDAY"] = "TUESDAY";
    Day["WEDNESDAY"] = "WEDNESDAY";
    Day["THURSDAY"] = "THURSDAY";
    Day["FRIDAY"] = "FRIDAY";
    Day["SATURDAY"] = "SATURDAY";
})(Day = Day || (Day = {}));
export const DayShort = {
    [Day.SUNDAY]: 'Sun',
    [Day.MONDAY]: 'Mon',
    [Day.TUESDAY]: 'Tue',
    [Day.WEDNESDAY]: 'Wed',
    [Day.THURSDAY]: 'Thu',
    [Day.FRIDAY]: 'Fri',
    [Day.SATURDAY]: 'Sat'
};
export const DayValue = {
    [Day.SUNDAY]: 0,
    [Day.MONDAY]: 1,
    [Day.TUESDAY]: 2,
    [Day.WEDNESDAY]: 3,
    [Day.THURSDAY]: 4,
    [Day.FRIDAY]: 5,
    [Day.SATURDAY]: 6
};
export const DayLabel = {
    [Day.SUNDAY]: 'Sunday',
    [Day.MONDAY]: 'Monday',
    [Day.TUESDAY]: 'Tuesday',
    [Day.WEDNESDAY]: 'Wednesday',
    [Day.THURSDAY]: 'Thursday',
    [Day.FRIDAY]: 'Friday',
    [Day.SATURDAY]: 'Saturday'
};
