var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Type } from "class-transformer";
import { CompanyLite } from "./Company.js";
import { ProjectLite } from "./Project.js";
import { ProjectRole } from "./ProjectRole.js";
import { User, UserLite } from "./User.js";
class ProjectUserBase {
    constructor() {
        this.project_role = ProjectRole.TRADE_CONTRACTOR;
        this.company = new CompanyLite();
    }
    get profile_picture() { return this.user ? this.user.profile_picture : undefined; }
    get id() { return this.user ? this.user.id : -1; }
    ;
}
__decorate([
    Type(() => CompanyLite)
], ProjectUserBase.prototype, "company", void 0);
export class ProjectUserLite extends ProjectUserBase {
    constructor() {
        super(...arguments);
        this.user = new UserLite();
    }
}
__decorate([
    Type(() => UserLite)
], ProjectUserLite.prototype, "user", void 0);
export class ProjectUser extends ProjectUserLite {
    constructor() {
        super(...arguments);
        this.user = new User();
    }
}
__decorate([
    Type(() => User)
], ProjectUser.prototype, "user", void 0);
export class AuthProjectUser {
    constructor() {
        this.project = new ProjectLite();
        this.project_user = new ProjectUser();
        this.permissions = [];
    }
}
__decorate([
    Type(() => ProjectLite)
], AuthProjectUser.prototype, "project", void 0);
__decorate([
    Type(() => ProjectUser)
], AuthProjectUser.prototype, "project_user", void 0);
