var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Exclude, Type } from "class-transformer";
export class PaginationResponse {
    constructor(type) {
        this.maxCount = 0;
        this.offset = 0;
        this.values = [];
        this.type = type;
    }
}
__decorate([
    Exclude()
], PaginationResponse.prototype, "type", void 0);
__decorate([
    Type(options => {
        return (options === null || options === void 0 ? void 0 : options.newObject).type;
    })
], PaginationResponse.prototype, "values", void 0);
;
