import { Company } from './../../models/Company.js';
import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { InsightsReport, ReportActivityLite, ReportDelayDate } from '../../models/reports/InsightsReport.js';
import { ApiService } from './api-base.js';
import { GanttReport } from '../../models/reports/GanttReport.js';
import { ProjectDailyReport } from '../../models/reports/ProjectDailyReport.js';
const createGanttReportParams = (report) => {
    var _a;
    return {
        report: {
            start_date: report.start_date,
            end_date: report.end_date,
            company_ids: ((_a = report.companies) === null || _a === void 0 ? void 0 : _a.map(c => c.id)) || [],
            company_shares: report.company_share_ids || [],
            user_shares: report.user_share_ids || []
        }
    };
};
export const ReportService = {
    createGanttReport: (projectId, report) => ApiService.post(`/projects/${projectId}/reports/gantt`, createGanttReportParams(report)),
    getPublicGanttReport: (shareToken) => ApiService.get(`/public_reports/gantt/${shareToken}`)
        .pipe(map((response) => plainToInstance(GanttReport, response))),
    getPublicProjectDailyReport: (shareToken) => ApiService.get(`/public_reports/daily/${shareToken}`)
        .pipe(map((response) => plainToInstance(ProjectDailyReport, response))),
    getInsightsReport: (projectId, companyId) => {
        let request = ApiService.get(`/projects/${projectId}/reports/insights`);
        if (companyId !== undefined) {
            request = ApiService.get(`/projects/${projectId}/reports/insights/companies/${companyId}`);
        }
        return request.pipe(map((response) => plainToInstance(InsightsReport, response)));
    },
    getCompaniesForInsightsReport: (projectId) => ApiService.get(`/projects/${projectId}/reports/insights/companies`)
        .pipe(map((response) => plainToInstance(Company, response))),
    getReportActivities: (projectId, type, companyId, filters, withPauseDays, query) => {
        let requestUrl = `/projects/${projectId}/reports/insights/activities?type=${type}`;
        requestUrl += filters === null || filters === void 0 ? void 0 : filters.map(f => `&filters[]=${f}`).join('');
        if (companyId !== undefined) {
            requestUrl += `&company_id=${companyId}`;
        }
        if (withPauseDays) {
            requestUrl += '&with_pause_days=true';
        }
        return ApiService.getWithQuery(requestUrl, query)
            .pipe(map((response) => response.map(r => {
            r.activity.status = r.status;
            r.activity.pause_days = r.pause_days;
            return r.activity;
        })), map((response) => plainToInstance(ReportActivityLite, response)));
    },
    getReportRainDelays: (projectId, companyId, query) => {
        let requestUrl = `/projects/${projectId}/reports/insights/rain_delays`;
        if (companyId !== undefined) {
            requestUrl += `?company_id=${companyId}`;
        }
        return ApiService.getWithQuery(requestUrl, query)
            .pipe(map((response) => response.map(r => {
            r.rain_delay.affected_activities = r.affected_activities;
            return r.rain_delay;
        })), map((response) => plainToInstance(ReportDelayDate, response)));
    },
    getReportRainDelayActivities: (projectId, delayDateId, companyId) => {
        let requestUrl = `/projects/${projectId}/reports/insights/rain_delays/${delayDateId}`;
        if (companyId !== undefined) {
            requestUrl += `?company_id=${companyId}`;
        }
        return ApiService.get(requestUrl)
            .pipe(map((response) => ({
            rain_delay: response.rain_delay,
            report_activities: plainToInstance(ReportActivityLite, response.report_activities.map(r => {
                r.activity.status = r.status;
                return r.activity;
            }))
        })));
    },
};
