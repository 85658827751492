var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Transform, Type } from "class-transformer";
import { ActivityLite } from "../Activity.js";
import { CompanyLite } from "../Company.js";
import { dateUtils } from "../DateUtils.js";
export class GanttReport {
    constructor() {
        this.start_date = new Date();
        this.end_date = new Date();
        this.company_share_ids = [];
        this.user_share_ids = [];
    }
}
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], GanttReport.prototype, "start_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], GanttReport.prototype, "end_date", void 0);
__decorate([
    Type(() => CompanyLite)
], GanttReport.prototype, "companies", void 0);
__decorate([
    Type(() => ActivityLite)
], GanttReport.prototype, "activities", void 0);
