import styled from "styled-components";
import A4Container from "../../global/layouts/A4Container";
import { Theme } from "../../../styles/theme";
import Column from "../../global/layouts/Column";
import Row from "../../global/layouts/Row";
import { ActivityDailyReportState, ActivityDailyReportStateLabel, ActivityLite, ActivityState,
   CompanyLite, LocationGroup, ProjectDailyReport, ReportService, activityUtils, dateUtils, projectDailyReportUtils
 } from "@lookahead/core";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import ProfileImageLayout from "../../global/layouts/ProfileImageLayout";
import AppText from "../../global/AppText";
import ProjectImage from "../../../assets/project_image.png";
import { useParams } from "react-router-dom";

const locationGroups: LocationGroup[] = [];
let l = new LocationGroup();
l.id = 3;
l.name = 'Tier 3';
l.parent_id = 2;
locationGroups.push(l);
l = new LocationGroup();
l.id = 2;
l.name = 'Tier 2';
l.parent_id = 1;
l = new LocationGroup();
locationGroups.push(l);
l.id = 1;
l.name = 'Tier 1';
locationGroups.push(l);

const companies: CompanyLite[] = [];
let c = new CompanyLite();
c.id = 1;
c.name = 'Company Alpha';
c.background_color = '#7BEE98';
companies.push(c);
c = new CompanyLite();
c.id = 2;
c.name = 'Company Beta';
c.background_color = '#7B0E06';
companies.push(c);
c = new CompanyLite();
c.id = 3;
c.name = 'Company Conto';
c.background_color = '#dd8527';
companies.push(c);

const today: {activity: ActivityLite, dailyReportState?: ActivityDailyReportState}[] = [];
const tomorrow: {activity: ActivityLite, dailyReportState?: ActivityDailyReportState}[] = [];
[1,2,3,4,5,6,7,8,9,10].forEach((_, i) => {
  let a = new ActivityLite();
  a.name = `Activity ${i}`;
  a.planned_start_date = moment().subtract(1, 'weeks').toDate();
  a.planned_end_date = moment().toDate();
  a.location_groups = locationGroups;
  a.location_group_id = 3;
  let dailyReportState: ActivityDailyReportState;
  if (i < 3) {
    a.state = ActivityState.START;
    a.company = companies[0];
    dailyReportState = ActivityDailyReportState.START;
  } else if (i < 6) {
    a.state = ActivityState.PAUSE;
    a.company = companies[1];
    dailyReportState = ActivityDailyReportState.PAUSE;
  } else {
    a.state = undefined;
    a.company = companies[2];
    dailyReportState = ActivityDailyReportState.NO_RESPONSE;
  }
  today.push({activity: a, dailyReportState});
});
[1,2,3,4,5,6].forEach((_, i) => {
  let a = new ActivityLite();
  a.name = `Activity2 ${i}`;
  a.location_groups = locationGroups;
  a.location_group_id = 3;
  a.planned_start_date = moment().subtract(1, 'weeks').toDate();
  a.planned_end_date = moment().toDate();
  a.days_remaining = i;
  if (i < 3) {
    a.company = companies[0];
  } else if (i < 6) {
    a.company = companies[1];
  } else {
    a.company = companies[2];
  }
  tomorrow.push({activity: a, dailyReportState: undefined});
});

const TdCompany = ({company}: {company: CompanyLite}) => (
  <td style={{minWidth: 180}}>
    <ProfileImageLayout record={company} size={26} profileMarginLeft={0} profileMarginRight={6} style={{margin: '2px 0'}}>
      <AppText>{company.name}</AppText>
    </ProfileImageLayout>
  </td>
);;

const ActivityDetailsRow = ({activity, last}: {activity: ActivityLite, last?: boolean}) => {
  return (
    <ProfileImageLayout record={activity.company} profileMarginLeft={0} size={32}
        style={{padding: '6px 0', marginRight: -14, borderBottom: `1px solid ${last ? 'transparent' : Theme.colors.tableBorder}`}}>
      <Column>
        <AppText tiny>{activity.name}</AppText>
        <AppText tiny>{activityUtils.locationStr(activity)}</AppText>
        <AppText tiny>{dateUtils.format(activity.planned_start_date, 'M/D/YY')} - {dateUtils.format(activity.planned_end_date, 'M/D/YY')}</AppText>
      </Column>
    </ProfileImageLayout>
  );
}

const ReportTable = styled.table<{primaryColor: string, secondaryColor: string}>`
  border: 1px solid ${Theme.colors.tableBorder};
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: left;
    color: ${props => props.primaryColor};
    font-weight: bold;
  }
  td, th {
    border: 1px solid ${Theme.colors.tableBorder};
    padding: 2px 13px;
  }
  tr > th:first-child {
    border-left: 5px solid ${props => props.primaryColor};
  }
  tr > td:first-child {
    border-left: 5px solid ${props => props.secondaryColor};
  }

  @media print {
    tr    { page-break-inside:avoid; page-break-after:auto }
    td    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group }
    tfoot { display:table-footer-group }
    }
`;

const ActivityReportTable = ({activitiesMap, name, groupByCompanies, primaryColor, secondaryColor, style}:
    {activitiesMap: Map<string, ActivityLite[]>, name: string, groupByCompanies: boolean, primaryColor: string, secondaryColor: string, style?: any}) => {
      const groupedActivities = useMemo(() => {
        let groups = new Map<string, ActivityLite[]>();
        if (!activitiesMap) {
          return groups;
        }
        if (groupByCompanies) {
          [...activitiesMap.entries()].forEach(([_, activities]) => {
            if (activities.length === 0) {
              return;
            }
            groups.set(activities[0].company.name, activities);
          });
        } else {
          groups = activitiesMap;
        }
        return groups;
      }, [activitiesMap, groupByCompanies])
  return (
    <ReportTable primaryColor={primaryColor} secondaryColor={secondaryColor} style={{...style}}>
      <thead>
        <tr>
          <th>{name}</th>
        </tr>
      </thead>
      <tbody>
        {Array.from(groupedActivities.keys()).map(k =>
        <tr key={k}>
          <td>
            <Column>
              <AppText style={{marginTop: 2, paddingBottom: 4, marginRight: -14, borderBottom: `1px solid ${Theme.colors.tableBorder}`}}>{groupByCompanies ? k : ActivityDailyReportStateLabel[k]}</AppText>
              {groupedActivities.get(k).map((activity, i) =>
              <ActivityDetailsRow key={activity.id} activity={activity} last={i === groupedActivities.get(k).length - 1}/>
              )}
            </Column>
          </td>
        </tr>
        )}
      </tbody>
    </ReportTable>
  );
};

const DailyReportPage = () => {
  const { token } = useParams();
  const [report, setReport] = useState<ProjectDailyReport | undefined>(undefined);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (token === undefined) {
      console.log('TOKEN IS UNDEFINED');
      setReady(true);
      return;
    }
    console.log(token);
    ReportService.getPublicProjectDailyReport(token).subscribe({
      next: response => {
        console.log(response);
        setReport(response);
        setReady(true);
      },
      error: error => {
        console.log(error);
        setReady(true);
      }
    });
  }, [token]);

  return (
    <div>
      {ready && !report && <div>Daily Report Not Found</div>}
      {ready && report &&
      <A4Container>
        <Row justifyContent="space-between" style={{borderBottom: `1px solid ${Theme.colors.tableBorder}`, marginBottom: 12}}>
          <Column style={{paddingBottom: 12}}>
          <AppText lg bold style={{marginBottom: 4}}>{report.project.name}</AppText>
          <AppText>{report.project.address1} {report.project.city}, {report.project.state}</AppText>
          <AppText>Daily Report - {dateUtils.format(report.date, 'MMMM DD, YYYY')}</AppText>
          </Column>
          {report.project.profile_picture && <img alt="Project" src={report.project.profile_picture} width='auto' height='85'/>}
        </Row>
        <ReportTable primaryColor={'#B70B0B'} secondaryColor={'#ECAEAE'} style={{marginBottom: 12}}>
          <thead>
            <tr>
              <th>Company</th>
              <th>Activities</th>
              <th>Summary</th>
              <th>Manpower</th>
            </tr>
          </thead>
          <tbody>
            {report.company_reports && report.company_reports.entries && [...report.company_reports?.entries()].map(([company_id, summary]) =>
            <tr key={company_id}>
              <TdCompany company={summary.company} />
              <td>{projectDailyReportUtils.companySummaryActivityCount(summary)}</td>
              <td>{projectDailyReportUtils.companySummaryText(summary)}</td>
              <td>{summary.report?.manpower}</td>
            </tr>
            )}
          </tbody>
        </ReportTable>
        {projectDailyReportUtils.summariesWithNotes(report.company_reports).length > 0 &&
        <ReportTable primaryColor={'#0B65B7'} secondaryColor={'#B3CFE9'} style={{marginBottom: 12}}>
          <thead>
            <tr>
              <th colSpan={2}>Notes</th>
            </tr>
          </thead>
          <tbody>
            {projectDailyReportUtils.summariesWithNotes(report.company_reports).map((summary, i) =>
            <tr key={i}>
              <TdCompany company={summary.company} />
              <td>{summary.report.notes}</td>
            </tr>
            )}
          </tbody>
        </ReportTable>
        }
        <Column>
          <ActivityReportTable primaryColor={'#6AA54E'} secondaryColor={'#D2E4CA'} name={"Today's Activities"} activitiesMap={report.day_activities} groupByCompanies={false} style={{marginBottom: 12}}/>
          <ActivityReportTable primaryColor={'#DF4300'} secondaryColor={'#F5C7B3'} name={"Tomorrow's Activities"} activitiesMap={report.tomorrow_activities as any} groupByCompanies={true} style={{marginBottom: 12}}/>
          {false && <ActivityReportTable primaryColor={'#8A00DF'} secondaryColor={'#DCB3F6'} name={'Delay Events'} activitiesMap={report.day_activities}groupByCompanies={true} style={{marginBottom: 12}}/>}
        </Column>
      </A4Container>
      }
    </div>
  );
};
export default DailyReportPage;