import AppText from "../../global/AppText";
import AppInput from "../../global/inputs/AppInput";
import Column from "../../global/layouts/Column";
import MainLayout from "../../global/layouts/MainLayout";
import AppButton from "../../global/buttons/AppButton";
import {Link} from 'react-router-dom';
import { useNavigate, useParams} from 'react-router-dom';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppErrorMessage from "../../global/AppErrorMessage";
import { FadeLoader } from 'react-spinners';
import { Theme } from "../../../styles/theme";
import { AuthService, UserLite } from '@lookahead/core';
import Row from "../../global/layouts/Row";

const PasswordResetPage = () => {
  const { token } = useParams();
  const [ isReady, setIsReady ] = useState(false);
  const navigate = useNavigate();
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ user, setUser ] = useState<UserLite | undefined>(undefined);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
                  .required('Password is required')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    "Must Contain 8 Characters and at least 1 uppercase and lowercase letter, 1 number and 1 special character (!@#$%^&*)"
                  ),
    passwordConfirm: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data: any) => {
    if (!token || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    AuthService.resetPassword(token, data.password)
      .subscribe({
        next: () => { setIsSubmitting(false); navigate('/password-changed', {replace: true}); },
        error: () => { setIsSubmitting(false) },
      });
  };

  useEffect(() => {
    if (token === undefined) {
      setIsReady(true);
      return;
    }
    AuthService.userResetPassword(token).subscribe({
      next: user => {
        reset({
          password: '',
          passwordConfirm: ''
        });
        setUser(user);
        setIsReady(true);
      },
      error: _ => { setIsReady(true); }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout transparent style={{justifyContent: 'center', display: 'flex'}}>
      <Column style={{maxWidth: 600}}>
        {!isReady &&
          <Column justifyContent="center" alignItems="center">
            <FadeLoader />
            <AppText>Getting token...</AppText>
          </Column>
        }
        {isReady && user === undefined &&
        <>
          <Column style={{backgroundColor: Theme.colors.red50, padding: 10, borderRadius: 5, border: `1px solid ${Theme.colors.red100}`}}>
            <AppText lg style={{marginBottom: 8}}>Reset Password Token Expired</AppText>
            <AppText sm>Reset Password Token has expired.</AppText>
          </Column>
        </>
        }
        {isReady && user !== undefined &&
        <form style={ { display: 'flex', flexDirection: 'column'} } onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))} >
          <AppText xl bold style={{marginBottom: 8}}>Reset Password</AppText>
          <AppText sm style={{marginBottom: 28}}>Set the new password for your account so you can login and access all of the features.</AppText>
          <AppText sm style={{marginBottom: 8}}>Password</AppText>
          <AppInput placeholder="******" type="password"  {...register('password')} error={!!errors.password}/>
          <AppErrorMessage name='password' errors={errors}/>
          <AppText sm style={{marginTop: 13, marginBottom: 8}}>Confirm Password</AppText>
          <AppInput placeholder="******" type="password" {...register('passwordConfirm')} error={!!errors.passwordConfirm}/>
          <AppErrorMessage name='passwordConfirm' errors={errors}/>
          <AppButton loading={isSubmitting} style={{marginTop: 24}} type='submit' text='Reset Password' />
        </form>
        }
      </Column>
    </MainLayout>
  )
}
export default PasswordResetPage;