import { Observable } from 'rxjs';
import { StoreKey, LocalStore } from '@lookahead/core';
import { ClassConstructor, plainToInstance } from 'class-transformer';

const setItem = (key: StoreKey, value: any) => new Observable<void>(subscriber => {
  localStorage.setItem(key, JSON.stringify(value));
  subscriber.next()
  subscriber.complete();
  subscriber.unsubscribe();
});

const clearItem = (key: StoreKey) => new Observable<void>(subscriber => {
  localStorage.removeItem(key);
  subscriber.next()
  subscriber.complete();
  subscriber.unsubscribe();
});

const getItem = <T = any>(key: StoreKey, cls: (ClassConstructor<T> | 'string') = 'string') =>
  new Observable<string | T | undefined>(subscriber => {
    try {
      const valueStr = localStorage.getItem(key);
      if (!valueStr) {
        subscriber.next(undefined);
      }
      else if (cls !== 'string') {
        subscriber.next(plainToInstance(cls, JSON.parse(valueStr)));
      }
      else {
        subscriber.next(valueStr);
      }
      subscriber.complete();
      subscriber.unsubscribe();
    } catch(_) {
      subscriber.error(new Error(`error getting ${key} from local storage`));
      subscriber.complete();
      subscriber.unsubscribe();
    }
  });

export const LocalStorageService: LocalStore = {
  setItem,
  getItem,
  clearItem
}