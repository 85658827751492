var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Type } from "class-transformer";
import { ProfilePictureRecord } from "./ProfilePictureRecord.js";
import { TimeZone } from "./TimeZone.js";
export class ProjectDailyReportEmail {
}
export class ProjectLite extends ProfilePictureRecord {
    constructor() {
        super(...arguments);
        this.name = '';
        this.time_zone = TimeZone['America/Los_Angeles'];
        this.current_activities_count = 0;
        this.daily_reports_enabled = false;
        this.project_daily_report_emails = [];
    }
    get initials() {
        return this.name !== undefined ? this.name[0] : '';
    }
}
__decorate([
    Type(() => ProjectDailyReportEmail)
], ProjectLite.prototype, "project_daily_report_emails", void 0);
