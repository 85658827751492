import styled from "styled-components";
import { ProfilePictureRecord } from "@lookahead/core";
import ProfileImage from "../ProfileImage";

type ProfileImageLayoutProps = {
  record?: ProfilePictureRecord;
  size: number;
  children?: any;
  profileMarginTop?: number;
  profileMarginLeft?: number;
  profileMarginRight?: number;
  style?: any;
} & typeof defaultProps;

const defaultProps = {
  size: 32
};

const ProfileImageLayout: React.FC<ProfileImageLayoutProps> = (props) => {
  return (
      <Layout style={props.style}>
        <ProfileLayout
            profileMarginTop={props.profileMarginTop}
            profileMarginLeft={props.profileMarginLeft}
            profileMarginRight={props.profileMarginRight}>
          <ProfileImage size={props.size} record={props.record}/>
        </ProfileLayout>
        <ContentLayout>
          {props.children}
        </ContentLayout>
      </Layout>
  );
}
ProfileImageLayout.defaultProps = defaultProps;
export default ProfileImageLayout;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ProfileLayout = styled.div<{profileMarginTop?: number, profileMarginLeft?: number, profileMarginRight?: number}>`
  margin-top: ${props => props.profileMarginTop !== undefined ? props.profileMarginTop : 2}px;
  margin-left: ${props => props.profileMarginLeft !== undefined ? props.profileMarginLeft : 12}px;
  margin-right: ${props => props.profileMarginRight !== undefined ? props.profileMarginRight : 12}px;
`;
const ContentLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;