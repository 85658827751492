import styled from "styled-components";
import { Theme } from "../../../styles/theme";
import Column from "./Column";

const Card = styled(Column)`
  border-radius: 8px;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  padding: 4px;
  background-color: ${Theme.colors.white};
`;
export default Card;