import { dateUtils, ReportService } from '@lookahead/core';
import { GanttReport } from '@lookahead/core/dist/models/reports/GanttReport';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppText from '../../global/AppText';
import AppGanttChart from '../../global/ganttChart/AppGanntChart';
import Card from '../../global/layouts/Card';
import Column from '../../global/layouts/Column';
import Row from '../../global/layouts/Row';
import { Theme } from '../../../styles/theme';

const GanttReportPage = () => {
  const { token } = useParams();
  const [report, setReport] = useState<GanttReport | undefined>(undefined);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (token === undefined) {
      setReady(true);
      return;
    }
    ReportService.getPublicGanttReport(token).subscribe({
      next: response => {
        setReport(response);
        setReady(true);
      },
      error: _ => {
        setReady(true);
      }
    });
  }, [])
  return (
    <Column style={{backgroundColor: Theme.colors.gray50, height: '100vh', paddingBottom: 75}}>
      <Row style={{padding: 16, backgroundColor: Theme.colors.white, alignItems: 'flex-end'}}>
        <img width={33} height={33} src="/icon.png" alt="Lookahead Icon" />
        <AppText bold lg style={{marginBottom: 3}}>Lookahead</AppText>
      </Row>
      <Card style={{margin: 13, maxWidth: 600}}>
        <AppText sm color={Theme.colors.gray200}>Project: {report?.project_name || '-'}</AppText>
        {report?.company_name && <AppText sm color={Theme.colors.gray200}>Company: {report?.company_name || '-'}</AppText>}
        <AppText sm color={Theme.colors.gray200}>Start Date: {dateUtils.format(report?.start_date, 'MM/DD/YYYY') || '-'}</AppText>
        <AppText sm color={Theme.colors.gray200}>End Date: {dateUtils.format(report?.end_date, 'MM/DD/YYYY') || '-'}</AppText>
      </Card>
      <AppGanttChart report={report} />
    </Column>
  );
};
export default GanttReportPage;