import Column from "../../global/layouts/Column";
import MainLayout from "../../global/layouts/MainLayout";
import pickMobileApp from '../../../assets/pickMobileApp.png';
import googlePlayButton from '../../../assets/googlePlayButton.png';
import appStoreButton from '../../../assets/appStoreButton.png';
import Row from "../../global/layouts/Row";
import AppText from "../../global/AppText";
import { Theme } from "../../../styles/theme";

const InviteAcceptPage = () => (
  <MainLayout transparent style={{backgroundColor: '#68ABFF'}}>
    <Column>
      <Row justifyContent="center" style={{backgroundColor: '#ffffff', borderRadius: 300, marginBottom: 24}}>
        <img src={pickMobileApp} alt="Welcome to Lookahead" style={{maxWidth: 345}}/>
      </Row>
      <AppText xxl bold color={Theme.colors.white} style={{textAlign: 'center', fontFamily: 'RobotoBold'}}>Pick your</AppText>
      <AppText xxl bold color={Theme.colors.white} style={{textAlign: 'center', marginBottom: 19, fontFamily: 'RobotoBold'}}>mobile device</AppText>
      <div style={{border: `2px solid ${Theme.colors.white}`, width: '100%', marginBottom: 11}}></div>
      <AppText color={Theme.colors.white} style={{textAlign: 'center'}}>Use the links below to download LookAhead.</AppText>
      <Column justifyContent='center' alignItems='center'>
        <a href="https://play.google.com/store/apps/details?id=com.lookahead.lookahead">
          <img src={googlePlayButton} alt="Google Play Link" style={{maxWidth: 345, marginBottom: 24, marginTop: 30}}/>
        </a>
        <a href="https://apps.apple.com/us/app/lookahead-scheduling/id1670188286">
          <img src={appStoreButton} alt="App Store Link" style={{maxWidth: 345, marginBottom: 24}}/>
        </a>
      </Column>
    </Column>
  </MainLayout>
)
export default InviteAcceptPage;