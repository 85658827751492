var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Transform, Type, plainToClass } from "class-transformer";
import { ActivityLite, ActivityDailyReportStateLabel } from "../Activity.js";
import { CompanyLite } from "../Company.js";
import { dateUtils } from "../DateUtils.js";
import { ProjectLite } from "../Project.js";
import { CompanyDailyReport } from "../CompanyDailyReport.js";
export const projectDailyReportUtils = {
    summariesWithNotes: (company_reports) => {
        const summaries = [];
        [...company_reports.values()].forEach(summary => {
            var _a;
            if ((_a = summary.report) === null || _a === void 0 ? void 0 : _a.notes) {
                summaries.push(summary);
            }
        });
        return summaries;
    },
    companySummaryActivityCount: (summary) => {
        return Array.from(summary.report_summary.values()).reduce((val, curr) => val + curr);
    },
    companySummaryText: (summary) => {
        const single = 'Activity';
        const plural = 'Activities';
        return Array.from(summary.report_summary.entries()).map(([state, number]) => {
            let actText = number === 1 ? single : plural;
            let label = ActivityDailyReportStateLabel[state];
            return `${number} ${actText} ${label}`;
        }).join(',');
    }
};
export class CompanyDailyReportWithSummary {
}
__decorate([
    Type(() => CompanyLite)
], CompanyDailyReportWithSummary.prototype, "company", void 0);
__decorate([
    Type(() => CompanyDailyReport)
], CompanyDailyReportWithSummary.prototype, "report", void 0);
__decorate([
    Transform(value => {
        let map = new Map();
        for (let entry of Object.entries(value.value))
            map.set(entry[0], Number.parseInt(entry[1], 10));
        return map;
    }, { toClassOnly: true })
], CompanyDailyReportWithSummary.prototype, "report_summary", void 0);
export class ProjectDailyReport {
}
__decorate([
    Type(() => ProjectLite)
], ProjectDailyReport.prototype, "project", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ProjectDailyReport.prototype, "date", void 0);
__decorate([
    Type(() => ActivityLite)
], ProjectDailyReport.prototype, "activities", void 0);
__decorate([
    Transform(value => {
        let map = new Map();
        for (let entry of Object.entries(value.value))
            map.set(entry[0], plainToClass(ActivityLite, entry[1]));
        return map;
    }, { toClassOnly: true })
], ProjectDailyReport.prototype, "day_activities", void 0);
__decorate([
    Transform(value => {
        let map = new Map();
        for (let entry of Object.entries(value.value))
            map.set(entry[0], plainToClass(ActivityLite, entry[1]));
        return map;
    }, { toClassOnly: true })
], ProjectDailyReport.prototype, "tomorrow_activities", void 0);
__decorate([
    Transform(value => {
        let map = new Map();
        for (let entry of Object.entries(value.value))
            map.set(entry[0], plainToClass(CompanyDailyReportWithSummary, entry[1]));
        return map;
    }, { toClassOnly: true })
], ProjectDailyReport.prototype, "company_reports", void 0);
