import { plainToInstance } from 'class-transformer';
import { map, switchMap, forkJoin, of } from 'rxjs';
import { UserLite } from '../../models/User.js';
import { LoginResponse } from '../../models/AuthToken.js';
import { StoreKey } from '../../store/local-store.js';
import { ApiService } from './api-base.js';
import { apiConfig } from './api-config.js';
const setStores = (loginResponse) => {
    var _a, _b;
    return forkJoin([
        apiConfig.localStore.setItem(StoreKey.CURRENT_PROJECT, (_b = (_a = loginResponse === null || loginResponse === void 0 ? void 0 : loginResponse.auth_project_user) === null || _a === void 0 ? void 0 : _a.project) === null || _b === void 0 ? void 0 : _b.id),
        apiConfig.localStore.setItem(StoreKey.AUTH, loginResponse === null || loginResponse === void 0 ? void 0 : loginResponse.auth_token)
    ]).pipe(map(_ => loginResponse));
};
export const AuthService = {
    // throws own errors
    login: (password, projectId, email, phone, pushNotificationToken) => ApiService.post('/auth/login', { email, phone, password, project_id: projectId, push_notification_token: pushNotificationToken }, { auth: false, handleError: false }).pipe(map(value => plainToInstance(LoginResponse, value)), switchMap(response => setStores(response))),
    forgotPassword: (phone, email) => ApiService.get(ApiService.appendQueryParams('/auth/forgot_password', { phone, email }), { auth: false }),
    userResetPassword: (token) => ApiService.get(`/auth/reset_password/${token}/user`)
        .pipe(map(value => plainToInstance(UserLite, value))),
    resetPassword: (token, password) => ApiService.put(`/auth/reset_password`, { token, password }),
    deletePushNotificationToken: (token) => {
        if (!token) {
            return of(undefined);
        }
        return ApiService.delete(`/auth/push_notification_token/${token}`);
    },
    logout: (pushNotificationToken) => AuthService.deletePushNotificationToken(pushNotificationToken).pipe(switchMap(() => apiConfig.localStore.clearItem(StoreKey.AUTH))),
    getCurrentProjectId: () => apiConfig.localStore.getItem(StoreKey.CURRENT_PROJECT, 'string'),
    setCurrentProjectId: (projectId) => apiConfig.localStore.setItem(StoreKey.CURRENT_PROJECT, projectId)
};
