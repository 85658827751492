var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiConfig } from './../services/api/api-config.js';
import { from } from "rxjs";
const getApiFileUrl = (url) => {
    // if file url sent as relative path, prepend api host
    if (url === undefined || !url.startsWith('/')) {
        return url;
    }
    const BASE_URL = apiConfig.baseUrl;
    if (!BASE_URL) {
        return url;
    }
    let arr = BASE_URL.split('/');
    arr.splice(arr.length - 2, 2);
    return arr.join('/') + url;
};
const fetchFromUri = (uri) => from((() => __awaiter(void 0, void 0, void 0, function* () {
    if (!uri) {
        return undefined;
    }
    const blob = yield new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            resolve(xhr.response);
        };
        xhr.onerror = function (e) {
            reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", uri, true);
        xhr.send(null);
    });
    return blob;
}))());
export const FileUtils = {
    getApiFileUrl,
    fetchFromUri
};
