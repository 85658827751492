import { SizeProps } from "./propTypes";
import { Theme } from "./theme";

export const fontBySize = (props: SizeProps, defaultSize = Theme.fontSize.med) => {
    if (props.xl) return Theme.fontSize.xl;
    if (props.lg) return Theme.fontSize.lg;
    if (props.med) return Theme.fontSize.med;
    if (props.sm) return Theme.fontSize.sm;
    if (props.tiny) return Theme.fontSize.tiny;
    return defaultSize;
};