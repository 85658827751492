import { Theme } from "../../styles/theme";
import AppText from "./AppText";

export type AppErrorMessageProps = {
  name: string;
  errors?: any;
};

const AppErrorMessage = ({name, errors}: AppErrorMessageProps) => {
  if (!errors || !errors[name]) {
    return null;
  }
  return (
    <AppText style={{marginTop: 8}} sm color={Theme.colors.red100}>{errors[name].message}</AppText>
  );
}

export default AppErrorMessage;