var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Type } from 'class-transformer';
import { Comment } from "./Comment.js";
import { CompanyLite } from './Company.js';
import { CreatedByRecord } from './CreatedByRecord.js';
import { dateUtils } from "./DateUtils.js";
import { FileUtils } from './FileUtils.js';
import { Transform } from 'class-transformer';
export class Post extends CreatedByRecord {
    constructor() {
        super(...arguments);
        this.body = '';
        this.is_public = false;
        this.project = -1;
        this.comments = [];
        this.company = undefined;
        this.isNewPost = () => dateUtils.isToday(this.created_at);
    }
}
__decorate([
    Transform(({ value }) => FileUtils.getApiFileUrl(value), { toClassOnly: true })
], Post.prototype, "image", void 0);
__decorate([
    Type(() => Comment)
], Post.prototype, "comments", void 0);
__decorate([
    Type(() => CompanyLite)
], Post.prototype, "company", void 0);
;
