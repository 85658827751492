
import styled from 'styled-components';
import { SizeProps } from '../../../styles/propTypes';
import { fontBySize } from '../../../styles/styleSnippets';
import { Theme } from '../../../styles/theme';
import AppText from '../AppText';
import { ClipLoader } from 'react-spinners';

type ButtonProps = {
  text?: string;
  loading?: boolean;
  primary?: boolean;
  link?: boolean;
  bold?: boolean;
  warning?: boolean;
  disabled?: boolean;
  type?: string;
  style?: any;
} & SizeProps;

const AppButton = (props: ButtonProps) => (
  <ButtonContainer {...props as any}>
    <StyledText {...props as any} style={undefined}>{props.text}</StyledText>
    {props.loading && <ClipLoader color={Theme.colors.white} size={15} cssOverride={{marginLeft: 8}}/>}
  </ButtonContainer>
);
export default AppButton;

const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  border: 0;
  cursor: pointer;
  ${props => {
    if (props.link) {
      return ''
    }
    return `
      justify-content: center;
      align-items: center;
    `
  }};
  background-color: ${props => {
    if (props.link) return 'transparent';
    if (props.warning) return Theme.colors.red150;
    return Theme.colors.blue150;
  }};
  opacity: ${props => props.disabled && !props.link ? 0.5 : 1};
  ${props => !props.link && 'width: 100%'};
  padding: ${props => props.link ? 0 : 12}px;
  border-radius: 10px;
`;

const StyledText = styled(AppText)<ButtonProps>`
  color: ${props => {
    if (props.link) {
      if (props.warning) return Theme.colors.red150;
      if (props.disabled) return Theme.colors.gray300;
      return Theme.colors.blue200;
    }
    return Theme.colors.white;
  }};
  font-size: ${props => fontBySize(props)};
  font-weight: ${props => {
    if (props.link) {
      if (props.bold) return 'bold';
      return 'normal';
    }
    return 'bold';
  }};
`;