var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Record } from './Record.js';
import { dateUtils } from './DateUtils.js';
import { Transform } from 'class-transformer';
export var DelayDateType;
(function (DelayDateType) {
    DelayDateType["RAIN_DELAY"] = "RAIN DELAY";
    DelayDateType["HOLIDAY"] = "HOLIDAY";
})(DelayDateType = DelayDateType || (DelayDateType = {}));
;
export class DelayDate extends Record {
    constructor() {
        super(...arguments);
        // custom transformer needed to parse utc date string to local timezone date
        this.date = new Date();
    }
    get day_start_time() {
        const date = dateUtils.resetTime(new Date(this.date));
        date.setMinutes(this.start_time_minutes || 0);
        return date;
    }
    get day_end_time() {
        const date = dateUtils.resetTime(new Date(this.date));
        date.setMinutes(this.end_time_minutes || 0);
        return date;
    }
    static deserialize(params) {
        const c = new DelayDate();
        c.id = params.id;
        c.description = params.description;
        c.start_time_minutes = params.start_time_minutes;
        c.end_time_minutes = params.end_time_minutes;
        c.date = new Date(params.date);
        c.company_ids = params.company_ids;
        return c;
    }
    serialize() {
        return {
            id: this.id,
            date: dateUtils.format(this.date, 'MM/DD/YYYY'),
            description: this.description,
            start_time_minutes: this.start_time_minutes,
            end_time_minutes: this.end_time_minutes,
            company_ids: this.company_ids
        };
    }
}
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], DelayDate.prototype, "date", void 0);
;
