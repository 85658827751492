import { ProfilePictureRecord } from "./ProfilePictureRecord.js";
import { stringUtils } from "./StringUtils.js";
export class CompanyUserSelection {
}
;
export class CompanyLite extends ProfilePictureRecord {
    constructor() {
        super(...arguments);
        this.trade = '';
        this.project = -1;
        this.name = '';
    }
    get fullName() { return `${this.name} - ${this.trade}`; }
    ;
    get initials() { return stringUtils.getInitials(this.name); }
    ;
}
export class Company extends CompanyLite {
    constructor() {
        super(...arguments);
        this.user_count = 0;
    }
}
;
