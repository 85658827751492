import styled from "styled-components";
import { SizeProps } from "../../../styles/propTypes";
import { fontBySize } from "../../../styles/styleSnippets";
import { Theme } from "../../../styles/theme";

export type InputProps = {
  white?: boolean;
  gray?: boolean;
  transparent?: boolean;
  noPadding?: boolean;
  borderGray?: boolean;
  error?: boolean;
} & SizeProps;

const AppInput = styled.input<InputProps>`
  background-color: ${props => {
    if (props.error) return Theme.colors.red50;
    if (props.white) return Theme.colors.white;
    if (props.gray) return Theme.colors.gray50;
    if (props.transparent || props.borderGray) return 'transparent';
    return Theme.colors.gray50;
  }};
  border-width: 0px;
  ${props => props.borderGray && `
    border-color: ${Theme.colors.gray100};
    border-width: 2px;
  `}
  ${props => props.error && `
    border-color: ${Theme.colors.red100};
    border-width: 1px;
  `}
  border-radius: 8px;
  padding: ${props => {
    if (props.transparent || props.noPadding) return '0px';
    if (props.sm) return '6px 7px'
    return '9px 16px';
  }};
  font-size: ${props => fontBySize(props)};
  outline: none;
  ::placeholder {
    color: ${props => {
      if (props.white) return Theme.colors.gray200;
      return Theme.colors.gray250;
    }};
    opacity: 1;
  }
`;
export default AppInput;