import { ActivityLite, LocationGroup } from '@lookahead/core';
import { GanttReport } from '@lookahead/core/dist/models/reports/GanttReport';
import { GanttComponent, Inject, ColumnsDirective, ColumnDirective, Toolbar, PdfExport, Selection, ExcelExport  } from '@syncfusion/ej2-react-gantt';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Theme } from '../../../styles/theme';
import AppText from '../AppText';

const ColumnTemplate = (props: any) => {
  return (
    <div className='columnTemplate' style={{backgroundColor: 'gray'}}>
      <div style={{ display: props.value ? "inline-block" : 'none', width: '100%', position: "relative", bottom: "8px" }}>
        {props.value}
      </div>
    </div>
  )
};

const ColumnLabel = styled(AppText).attrs({
  tiny: true,
  color: Theme.colors.gray200
})`
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  padding: 4px;
  padding-bottom: 0px;
  margin: 13px;
  background-color: ${Theme.colors.white};
`;

type GanttChartProps = {
  report?: GanttReport
}

const AppGanttChart = (props: GanttChartProps) => {
  let ganttInstance: GanttComponent;
  const [data, setData] = useState<any[]>([]);

  const formatLocationData = (locationsData: any[], locations: LocationGroup[], activity: ActivityLite, parentLevel: number) => {
    if (locations.length === 0) {
      locationsData.push({
        id: activity.id,
        name: activity.name,
        companyName: activity.company?.name,
        start_date: activity.planned_start_date,
        end_date: activity.actual_end_date,
      });
      return locationsData;
    }
    let index = locationsData.findIndex(l => l.locationId === locations[0].id)
    if (index === -1) {
      locationsData.push({
        id: `l${locations[0].id}`,
        locationId: locations[0].id,
        name: locations[0].name,
        parentLevel: parentLevel,
        children: []
      });
      index = locationsData.length - 1;
    }
    if (locationsData[index].parentLevel < parentLevel) {
      locationsData[index].parentLevel = parentLevel;
    }
    let l = [...locations];
    l.splice(0, 1);
    return formatLocationData(locationsData[index].children, l, activity, --parentLevel)
  }

  const formatFormData = (activities: ActivityLite[]) => {
    let locationsData: any[] = [];
    let projectActivitiesData: any[] = [];
    activities.forEach((activity) => {
      if (activity.location_groups.length > 0) {
        formatLocationData(locationsData, activity.location_groups, activity, activity.location_groups.length);
      } else {
        projectActivitiesData.push({
          id: activity.id,
          name: activity.name,
          companyName: activity.company?.name,
          start_date: activity.planned_start_date,
          end_date: activity.actual_end_date,
          children: []
        })
      }
    });
    setData([...locationsData, ...projectActivitiesData]);
  }

  useEffect(() => {
    if (!props.report) {
      return;
    }
    formatFormData(props.report.activities)
  }, [props.report])
  /* let data = [
    { id: '001', name: 'Activity 1', parentLevel: 0, companyName: 'Company 1', start_date: new Date('02/08/2023'), end_date: new Date('02/15/2023')},
    {
      id: '',
      name: '5th Floor',
      parentLevel: 2,
      children: [
        { id: '001', name: 'Activity 1', parentLevel: 0, companyName: 'Company 1', start_date: new Date('02/08/2023'), end_date: new Date('02/15/2023')},
        {
          id: '',
          name: '6th Floor',
          parentLevel: 1,
          children: [
            { id: '002', name: 'Activity 1', parentLevel: 0, companyName: 'Company 1', start_date: new Date('02/08/2023'), end_date: new Date('02/15/2023') }
          ]
        }
      ]
    }
  ]; */
  const taskFields = {id: 'id', name: 'name', startDate: 'start_date', endDate: 'end_date', duration: 'duration', progress: 'progress', child: 'children' };
  const timelineSettings: any = {
    timelineUnitSize: 22,
    timelineViewMode: 'Month',
    topTier: {
      unit: 'Month',
      format: 'MMMM yyyy'
    },
    bottomTier: {
      unit: 'Day',
      count: 1,
      format: 'd',
    }
  };

  const splitterSettings = {
    position: '60%'
  };

  const rowDataBound = ({data, row}: any) => {
    const level = data.taskData.parentLevel;
    const opacity = (level * 0.05);
    row.style.background = `rgba(0,0,0,${opacity})`;
  };

  const toolbarOptions = ['PdfExport', 'ExcelExport'];

  const toolbarClick = (args: any) => {
    if (args.item.text === 'Pdf export') {
      ganttInstance.pdfExport();
    }
    if (args.item.text === 'Excel export') {
      ganttInstance.excelExport();
  }
  }

  const queryTaskbarInfo = (args: any) => {
    if (args.taskbarType === 'ParentTask') {
      const level = args.data.taskData.parentLevel;
      const opacity = (level * 0.05);
      args.taskbarBgColor = `rgba(0,0,0,${opacity})`;
    } else {
      args.taskbarBgColor = Theme.colors.blue100;
    }
  }

  return (
    <Wrapper>
    <GanttComponent ref={gantt => ganttInstance = gantt}
      allowPdfExport={true}
      allowExcelExport={true}
      collapseAllParentTasks={false}
      dataSource={data}
      taskFields={taskFields}
      rowDataBound={rowDataBound}
      queryTaskbarInfo={queryTaskbarInfo}
      splitterSettings={splitterSettings}
      toolbar={toolbarOptions}
      toolbarClick={toolbarClick}
      timelineSettings={timelineSettings}
      highlightWeekends={true}
      rowHeight={38}>
      <Inject services={[Toolbar, PdfExport, ExcelExport, Selection]}/>
      <ColumnsDirective>
        <ColumnDirective field='id'
          headerTemplate={() => <ColumnLabel>Id</ColumnLabel>}
          template={props => {
            if (props.taskData.locationId !== undefined) {
              return <span></span>
            }
              return <div>{props.id}</div>
            }
          }>
        </ColumnDirective>
        <ColumnDirective field='name' headerTemplate={() => <ColumnLabel>Activity</ColumnLabel>}></ColumnDirective>
        <ColumnDirective field='companyName' headerTemplate={() => <ColumnLabel>Company</ColumnLabel>}></ColumnDirective>
        <ColumnDirective field='start_date' width='110' headerTemplate={() => <ColumnLabel>Start</ColumnLabel>}></ColumnDirective>
        <ColumnDirective field='end_date' width='110' headerTemplate={() => <ColumnLabel>Finish</ColumnLabel>}></ColumnDirective>
        <ColumnDirective field='duration' width='110' headerTemplate={() => <ColumnLabel>Duration</ColumnLabel>}></ColumnDirective>
        {//<ColumnDirective field='progress' maxWidth='110' headerTemplate={() => <ColumnLabel>Progress</ColumnLabel>}></ColumnDirective>
      }
      </ColumnsDirective>
    </GanttComponent>
    </Wrapper>
  );
}
export default AppGanttChart;