import Column from "../../global/layouts/Column";
import MainLayout from "../../global/layouts/MainLayout";
import passwordChange from '../../../assets/passwordChange.png';
import Row from "../../global/layouts/Row";
import AppButton from "../../global/buttons/AppButton";
import {Link} from 'react-router-dom';

const PasswordChangedPage = () => (
  <MainLayout transparent>
    <Column>
      <Row justifyContent="center">
        <img src={passwordChange} alt="Password Changed Successfully" style={{maxWidth: 345, marginBottom: 24}}/>
      </Row>
      <Link to="/invite-accept">
        <AppButton text='Log In' />
      </Link>
    </Column>
  </MainLayout>
)
export default PasswordChangedPage;