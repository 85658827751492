import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import GanttReportPage from "./components/pages/gantt-report/GanttReportPage";
import InvitePage from "./components/pages/invite/InvitePage";
import InviteAcceptPage from "./components/pages/inviteAccept/InviteAcceptPage";
import LoginPage from "./components/pages/login/LoginPage";
import PasswordChangedPage from "./components/pages/passwordChanged/PasswordChangedPage";
import ResetPasswordPage from "./components/pages/resetPassword/ResetPasswordPage";
import { initWebConfig } from "./services/web-config";
import './App.css';
import DailyReportPage from "./components/pages/daily-report/DailyReportPage";

//initialize @lookahead/core configuration for web project
initWebConfig();

const App = () =>
<Router>
  <Routes>
    <Route path="/invite/:token" element={<InvitePage />} />
    <Route path="/invite-accept" element={<InviteAcceptPage />} />
    <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
    <Route path="/password-changed" element={<PasswordChangedPage />} />
    <Route path='/login' element={<LoginPage />} />
    <Route path='/gantt-report/:token' element={<GanttReportPage />} />
    <Route path='/daily-report/:token' element={<DailyReportPage />} />
  </Routes>
</Router>

export default App;
