import { ajax } from 'rxjs/ajax';
import { map, catchError, switchMap } from 'rxjs/operators';
import { uploadFileUtils } from '../../models/UploadFile.js';
import { AuthToken } from '../../models/AuthToken.js';
import { throwError } from 'rxjs';
import { apiConfig } from './api-config.js';
import { StoreKey } from '../../store/local-store.js';
const logMiddleware = (type, url, auth, body = null, response = null, error = null) => {
    if (response) {
        console.log(`RESPONSE: ${response === null || response === void 0 ? void 0 : response.status}`);
        console.log(response === null || response === void 0 ? void 0 : response.response);
    }
    else {
        console.log(`ERROR: ${error === null || error === void 0 ? void 0 : error.status}`);
        console.log((error === null || error === void 0 ? void 0 : error.response) ? error.response : error);
    }
};
const handleErrorMiddleware = (error) => {
    if (error.status === 401) {
        // DeviceEventEmitter.emit(DeviceEvents.UNAUTHORIZED);
    }
};
export class UploadProgress {
}
const onMapResponse = (type, url, response, options) => {
    if (apiConfig.showLog && options.showLogs !== false) {
        logMiddleware(type, url, (options.auth || false), null, response);
    }
    if (options.includeUploadProgress) {
        let finished = false;
        let progressPercent = undefined;
        if (response.total === undefined && response.loaded === undefined) {
            finished = true;
        }
        else {
            progressPercent = response.total === 0 ? 0 : response.loaded / response.total;
            if (isNaN(progressPercent)) {
                progressPercent = 1;
            }
        }
        return {
            progressPercent,
            finished,
            response: response.type === 'upload_progress' ? null : response.response
        };
    }
    return response.response;
};
const onCatchError = (type, url, auth = true, handleError = true, error) => {
    if (apiConfig.showLog) {
        logMiddleware(type, url, auth, null, null, error);
    }
    if (handleError) {
        setTimeout(() => {
            handleErrorMiddleware(error);
        }, 0);
    }
    return throwError(() => error);
};
const defaultOptions = {
    auth: true,
    handleError: true,
    formData: false
};
const objectToFormData = (body, formData = new FormData()) => {
    const createFormData = (fd, obj, subKeyStr = '') => {
        for (let i in obj) {
            let value = obj[i];
            if (value === undefined || value === null) {
                continue;
            }
            let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;
            if (value && typeof (value) === 'object') {
                if (uploadFileUtils.isBlob(value)) {
                    const typedVal = value;
                    fd.append(subKeyStrTrans, typedVal, typedVal.name);
                }
                else if (uploadFileUtils.isValidUploadFile(value)) {
                    const typedVal = value;
                    fd.append(subKeyStrTrans, uploadFileUtils.getRequestParams(typedVal));
                }
                else {
                    createFormData(fd, value, subKeyStrTrans);
                }
            }
            else {
                fd.append(subKeyStrTrans, value);
            }
        }
    };
    createFormData(formData, body);
    return formData;
};
const request = (method, url, body = undefined, options = defaultOptions) => {
    if (!apiConfig || !apiConfig.baseUrl || !apiConfig.localStore) {
        throw ('Error: apiConfig not initialize. set config using initApiConfig()');
    }
    return apiConfig.localStore.getItem(StoreKey.AUTH, AuthToken).pipe(switchMap(store => {
        const token = typeof store === 'string' ? undefined : store;
        const ajaxRequest = {
            url: `${apiConfig.baseUrl}${url}`,
            method: method,
            headers: {
                Authorization: options.auth ? `Bearer ${token === null || token === void 0 ? void 0 : token.token}` : undefined
            },
            body: body !== undefined ? body : undefined,
            includeUploadProgress: options.includeUploadProgress
        };
        if (options.formData) {
            ajaxRequest.body = objectToFormData(body);
        }
        else {
            ajaxRequest.headers['Content-Type'] = 'application/json';
        }
        if (apiConfig.showLog && options.showLogs !== false) {
            console.log(ajaxRequest);
        }
        return ajax(ajaxRequest)
            .pipe(catchError(error => onCatchError(method, url, options.auth, options.handleError, error)), map((response) => onMapResponse(method, url, response, options)));
    }));
};
const appendQueryParams = (url, queryParams) => {
    if (!url)
        return '';
    if (!queryParams || typeof queryParams !== 'object')
        return url;
    const separator = url.indexOf('?') === -1 ? '?' : '&';
    const queryString = Object.keys(queryParams)
        .map((key) => {
        if ((queryParams[key]) === undefined || queryParams[key] === '') {
            return;
        }
        if (Array.isArray(queryParams[key])) {
            return queryParams[key].map((value) => `${key}[]=${value}`).join('&');
        }
        return `${key}=${queryParams[key]}`;
    })
        .join('&');
    return `${url}${separator}${queryString}`;
};
export const ApiService = {
    appendQueryParams,
    get: (url, options = {}) => request('GET', url, undefined, Object.assign(Object.assign({}, defaultOptions), options)),
    getWithQuery: (url, query, options = {}) => ApiService.get(ApiService.appendQueryParams(url, { query }), options),
    getWithPagination: (cls, url, offset, query, options = {}) => ApiService.getWithQuery(ApiService.appendQueryParams(url, { offset }), query, options)
        .pipe(map((response) => {
        return response;
    })),
    post: (url, body, options = {}) => request('POST', url, body, Object.assign(Object.assign({}, defaultOptions), options)),
    put: (url, body, options = {}) => request('PUT', url, body, Object.assign(Object.assign({}, defaultOptions), options)),
    delete: (url, options = {}) => request('DELETE', url, undefined, Object.assign(Object.assign({}, defaultOptions), options)),
    //postFile
};
