const getInitials = (name) => {
    var _a, _b;
    let int = [...name.matchAll(new RegExp(/([A-Za-z]{1})[A-Za-z]+/, 'g'))] || [];
    return (((_a = int.shift()) === null || _a === void 0 ? void 0 : _a[1]) || '') + (((_b = int.pop()) === null || _b === void 0 ? void 0 : _b[1]) || '').toUpperCase();
};
const isEmail = (str) => {
    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(str);
};
const isValidPassword = (str) => {
    const regexExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return regexExp.test(str);
};
export const stringUtils = {
    getInitials,
    isEmail,
    isValidPassword
};
