import moment from 'moment-timezone';
var Day;
(function (Day) {
    Day["SUNDAY"] = "SUNDAY";
    Day["MONDAY"] = "MONDAY";
    Day["TUESDAY"] = "TUESDAY";
    Day["WEDNESDAY"] = "WEDNESDAY";
    Day["THURSDAY"] = "THURSDAY";
    Day["FRIDAY"] = "FRIDAY";
    Day["SATURDAY"] = "SATURDAY";
})(Day || (Day = {}));
const DayValue = {
    [Day.SUNDAY]: 0,
    [Day.MONDAY]: 1,
    [Day.TUESDAY]: 2,
    [Day.WEDNESDAY]: 3,
    [Day.THURSDAY]: 4,
    [Day.FRIDAY]: 5,
    [Day.SATURDAY]: 6
};
const DayArray = [
    Day.SUNDAY,
    Day.MONDAY,
    Day.TUESDAY,
    Day.WEDNESDAY,
    Day.THURSDAY,
    Day.FRIDAY,
    Day.SATURDAY,
];
const yesterday = (date = new Date) => {
    const yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
};
const timeSince = (date, i18n) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;
    let output = Math.floor(interval);
    if (interval > 1) {
        return output + ` ${i18n.t(output > 1 ? 'years' : 'year')}`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        output = Math.floor(interval);
        return output + ` ${i18n.t(output > 1 ? 'months' : 'month')}`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        output = Math.floor(interval);
        return output + ` ${i18n.t(output > 1 ? 'days' : 'day')}`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        output = Math.floor(interval);
        return output + ` hr${output > 1 ? 's' : ''}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        output = Math.floor(interval);
        return output + ` min${output > 1 ? 's' : ''}`;
    }
    return output + ` sec${output > 1 ? 's' : ''}`;
};
const isToday = (date, timezone) => {
    const d = moment(date);
    let today = moment();
    if (timezone) {
        today = moment().tz(timezone);
    }
    return d.isSame(today, 'date');
};
const formatTimeFromMinutes = (minutes) => {
    // Calculate hours and minutes
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    // Format the time string
    const formattedTime = `${formattedHours}${ampm}`;
    return formattedTime;
};
const sameDay = (date1, date2, timezone) => {
    if (date1 === undefined) {
        return false;
    }
    if (date2 === undefined) {
        return false;
    }
    let d1 = moment(date1);
    let d2 = moment(date2);
    if (timezone) {
        d1 = moment(date1).tz(timezone);
        d2 = moment(date2).tz(timezone);
    }
    return d1.isSame(d2, 'date');
};
const format = (date, format = 'M/D/YY') => {
    if (!date) {
        return '';
    }
    return moment(date).format(format);
};
const getTimeString = (date) => {
    return format(date, 'hh:mm A');
};
const resetTime = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
};
const getTimeFromMinutes = (minutes) => {
    const date = new Date();
    dateUtils.resetTime(date);
    date.setMinutes(minutes);
    return date;
};
const getMinutesFromMidnight = (date) => {
    return (date.getHours() * 60) + date.getMinutes();
};
const dateParser = (str) => {
    return new Date(str + ' ');
};
const datesBetween = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};
const daysBetween = (start, end) => {
    if (!start) {
        return 0;
    }
    if (!end) {
        return 0;
    }
    return moment(start).diff(moment(end), 'days');
};
const deserializeDate = (dateStr, format = 'YYYY-MM-DD') => {
    if (!dateStr) {
        return undefined;
    }
    const momentObj = moment(dateStr + ' ', format);
    return momentObj.isValid() ? momentObj.toDate() : new Date();
};
// returns days that do not fall in range of params
// ex: startDay: Monday, endDay: Friday => [Saturday, Sunday]
// also supports days turning over into next calendar week
// ex: startDay: Friday, endDay: Wednesday => [Thursday]
const daysNotInRange = (startDay, endDay) => {
    const days = [];
    let currIndex = -1;
    // if startDay is after endDay
    if (DayValue[startDay] > DayValue[endDay]) {
        currIndex = DayValue[endDay] + 1;
        do {
            days.push(DayArray[currIndex]);
            currIndex += 1;
        } while ((currIndex < DayValue[startDay]));
        return days;
    }
    currIndex = DayValue[startDay] - 1;
    if (DayValue[startDay] > 0) {
        while (currIndex >= 0) {
            days.push(DayArray[currIndex]);
            currIndex -= 1;
        }
    }
    currIndex = DayValue[endDay] + 1;
    if (DayValue[endDay] < 6) {
        while (currIndex <= 6) {
            days.push(DayArray[currIndex]);
            currIndex += 1;
        }
    }
    return days;
};
const getNextWorkday = (startDate, workingCalendar) => {
    if (!startDate) {
        return startDate;
    }
    if (!workingCalendar) {
        return startDate;
    }
    let currDate = startDate;
    const nonWorkdays = dateUtils.daysNotInRange(workingCalendar.start_day, workingCalendar.end_day);
    if (nonWorkdays.length === 7) {
        return undefined;
    }
    const isNonWorkday = (d, nonWorkdays) => nonWorkdays.find(n => DayValue[n] === d.getDay()) !== undefined;
    while (isNonWorkday(currDate, nonWorkdays)) {
        currDate = moment(currDate).add(1, 'day').toDate();
    }
    ;
    return currDate;
};
const getWeekdayDates = (monthDate, days) => {
    const month = monthDate.getMonth();
    let currentDate = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
    const returnDates = [];
    days.forEach(day => {
        currentDate = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
        // get the first weekday
        while (currentDate.getDay() !== DayValue[day]) {
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // get the rest of the weekday
        while (currentDate.getMonth() === month) {
            returnDates.push(new Date(currentDate.getTime()));
            currentDate.setDate(currentDate.getDate() + 7);
        }
    });
    return returnDates;
};
export const dateUtils = {
    timeSince,
    isToday,
    sameDay,
    format,
    getTimeString,
    resetTime,
    getMinutesFromMidnight,
    dateParser,
    datesBetween,
    daysBetween,
    deserializeDate,
    getWeekdayDates,
    daysNotInRange,
    getNextWorkday,
    yesterday,
    getTimeFromMinutes,
    formatTimeFromMinutes
};
