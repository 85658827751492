var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { WorkingCalendar } from './WorkingCalendar.js';
import { Transform, Type } from "class-transformer";
import { CompanyLite } from "./Company.js";
import { dateUtils } from "./DateUtils.js";
import { ProjectUserLite } from "./ProjectUser.js";
import { Record } from "./Record.js";
import { ScheduleAccessLevel, ScheduleLite } from "./Schedule.js";
import { UserLite } from './User.js';
import { LocationGroup } from './LocationGroup.js';
import { Comment } from './Comment.js';
export var ActivityType;
(function (ActivityType) {
    ActivityType["ACTIVITY"] = "ACTIVITY";
    ActivityType["DELAY_EVENT"] = "DELAY_EVENT";
})(ActivityType = ActivityType || (ActivityType = {}));
;
export const activityLinkUtils = {
    detailsText: (link) => `${link.link_relationship_type}` +
        `${link.lag_days ?
            ((link.lag_days < 0 ? ' - ' : ' + ') + `${Math.abs(link.lag_days)}`) :
            ''}`
};
export const activityUtils = {
    locationStr: (activity) => {
        if (activity === undefined || activity.location_groups.length === 0) {
            return '';
        }
        if (activity.location_groups.length === 1) {
            return activity.location_groups[0].name;
        }
        return `${activity.location_groups[activity.location_groups.length - 2].name} > ${activity.location_groups[activity.location_groups.length - 1].name}`;
    },
    isOwner: (activity, userId) => { var _a; return ((_a = activity.created_by) === null || _a === void 0 ? void 0 : _a.id) === userId; },
    isPublished: (activity) => { var _a; return ((_a = activity.schedule) === null || _a === void 0 ? void 0 : _a.schedule_access_level) === ScheduleAccessLevel.PUBLISHED; },
    hasStateToday: (activity, project_timezone) => (activity.state_date && (activity.state !== ActivityState.NOT_STARTED && activity.state !== ActivityState.DELAY_EVENT_PENDING)) ?
        dateUtils.isToday(activity.state_date, project_timezone) :
        false,
    hasStateDate: (activity, date, project_timezone) => (activity.state_date && activity.state !== ActivityState.NOT_STARTED) ?
        dateUtils.sameDay(activity.state_date, date, project_timezone) :
        false,
    canStartEarly: (activity) => (activity === null || activity === void 0 ? void 0 : activity.planned_start_date) !== undefined && (activity === null || activity === void 0 ? void 0 : activity.actual_start_date) === undefined && (activity === null || activity === void 0 ? void 0 : activity.planned_start_date) > new Date(),
    canFinish: (activity) => {
        if ((activity === null || activity === void 0 ? void 0 : activity.activity_type) === ActivityType.DELAY_EVENT) {
            return true;
        }
        return activity ? activityUtils.isDueToday(activity) || activityUtils.isLate(activity) : false;
    },
    canFinishEarly: (activity) => {
        return activityUtils.isEarly(activity);
    },
    sortByDate: (activities) => {
        return activities.sort((a, b) => {
            var _a, _b;
            const aStartDateTime = ((_a = a.planned_start_date) === null || _a === void 0 ? void 0 : _a.getTime()) || 0;
            const bStartDateTime = ((_b = b.planned_start_date) === null || _b === void 0 ? void 0 : _b.getTime()) || 0;
            return aStartDateTime - bStartDateTime;
        });
    },
    finishedOnTime: (activity) => !activity.is_finished,
    isDueToday: (activity) => !activity.is_finished && activity.days_remaining === 0,
    isOnTime: (activity) => (activity.is_finished && activity.end_dates_delta === 0) || activity.activity_type === ActivityType.DELAY_EVENT,
    isEarly: (activity) => {
        if (activity.is_finished && activity.end_dates_delta !== undefined) {
            return activity.end_dates_delta < 0;
        }
        if (!activity.is_finished && activity.days_remaining !== undefined) {
            return activity.days_remaining > 0;
        }
    },
    isLate: (activity) => {
        if (activity.is_finished && activity.end_dates_delta !== undefined) {
            return activity.end_dates_delta > 0;
        }
        if (!activity.is_finished && activity.days_remaining !== undefined) {
            return activity.days_remaining < 0;
        }
    },
    daysRemainingText: (activity, i18n) => {
        if (activity.is_finished) {
            return '';
        }
        const isPlural = Math.abs(activity.days_remaining || 0) !== 1;
        let i18nKey = '';
        if (activityUtils.isLate(activity)) {
            i18nKey = isPlural ? 'days_late' : 'day_late';
        }
        else {
            i18nKey = isPlural ? 'days_remaining' : 'day_remaining';
        }
        return ` ${Math.abs(activity.days_remaining || 0)} ${i18n.t(i18nKey)}`;
    }
};
export var ActivityStatusLabel;
(function (ActivityStatusLabel) {
    ActivityStatusLabel["EARLY"] = "Finished Early";
    ActivityStatusLabel["ON_TIME"] = "Finished On Time";
    ActivityStatusLabel["LATE"] = "Finished Late";
})(ActivityStatusLabel = ActivityStatusLabel || (ActivityStatusLabel = {}));
export var ActivityStatusState;
(function (ActivityStatusState) {
    ActivityStatusState["OPEN"] = "OPEN";
    ActivityStatusState["PENDING"] = "PENDING";
    ActivityStatusState["ON_DECK"] = "ON_DECK";
    ActivityStatusState["CLOSED"] = "CLOSED";
})(ActivityStatusState = ActivityStatusState || (ActivityStatusState = {}));
export const ActivityStatusStateLabel = {
    [ActivityStatusState.OPEN]: 'OPEN',
    [ActivityStatusState.PENDING]: 'PENDING',
    [ActivityStatusState.ON_DECK]: 'ON DECK',
    [ActivityStatusState.CLOSED]: 'CLOSED'
};
export var ActivityState;
(function (ActivityState) {
    ActivityState["START"] = "START";
    ActivityState["CONTINUE"] = "CONTINUE";
    ActivityState["PAUSE"] = "PAUSE";
    ActivityState["SYSTEM_PAUSE"] = "SYSTEM_PAUSE";
    ActivityState["FINISH"] = "FINISH";
    ActivityState["NOT_STARTED"] = "NOT_STARTED";
    ActivityState["DELAY_EVENT_PENDING"] = "DELAY_EVENT_PENDING";
})(ActivityState = ActivityState || (ActivityState = {}));
;
export var ActivityDailyReportState;
(function (ActivityDailyReportState) {
    ActivityDailyReportState["START"] = "START";
    ActivityDailyReportState["CONTINUE"] = "CONTINUE";
    ActivityDailyReportState["PAUSE"] = "PAUSE";
    ActivityDailyReportState["NO_RESPONSE"] = "NO_RESPONSE";
    ActivityDailyReportState["FINISHED_EARLY"] = "FINISHED_EARLY";
    ActivityDailyReportState["FINISH_ON_TIME"] = "FINISH_ON_TIME";
    ActivityDailyReportState["FINISHED_LATE"] = "FINISHED_LATE";
})(ActivityDailyReportState = ActivityDailyReportState || (ActivityDailyReportState = {}));
export const ActivityDailyReportStateLabel = {
    [ActivityDailyReportState.START]: 'Started',
    [ActivityDailyReportState.CONTINUE]: 'Continued',
    [ActivityDailyReportState.PAUSE]: 'Paused',
    [ActivityDailyReportState.NO_RESPONSE]: 'No Response',
    [ActivityDailyReportState.FINISHED_EARLY]: 'Finished Early',
    [ActivityDailyReportState.FINISH_ON_TIME]: 'Finished On Time',
    [ActivityDailyReportState.FINISHED_LATE]: 'Finished Late'
};
export var ActivityTimelineState;
(function (ActivityTimelineState) {
    ActivityTimelineState["START"] = "START";
    ActivityTimelineState["CONTINUE"] = "CONTINUE";
    ActivityTimelineState["PAUSE"] = "PAUSE";
    ActivityTimelineState["FINISH"] = "FINISH";
    ActivityTimelineState["START_EARLY"] = "START_EARLY";
    ActivityTimelineState["FINISH_EARLY"] = "FINISH_EARLY";
    ActivityTimelineState["UNKNOWN"] = "UNKNOWN";
    ActivityTimelineState["LATE_DAY"] = "LATE_DAY";
    ActivityTimelineState["TRUNCATE"] = "TRUNCATE";
    ActivityTimelineState["TRUNCATE_LATE"] = "TRUNCATE_LATE";
})(ActivityTimelineState = ActivityTimelineState || (ActivityTimelineState = {}));
export var ActivityAction;
(function (ActivityAction) {
    ActivityAction["PREDECESSOR_DATE_CHANGE"] = "PREDECESSOR_DATE_CHANGE";
    ActivityAction["EDIT"] = "EDIT";
    ActivityAction["CREATE"] = "CREATE";
    ActivityAction["DELETE"] = "DELETE";
})(ActivityAction = ActivityAction || (ActivityAction = {}));
export class TimelineStateLog {
}
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], TimelineStateLog.prototype, "state_date", void 0);
export var LinkRelationshipType;
(function (LinkRelationshipType) {
    LinkRelationshipType["FS"] = "FS";
    LinkRelationshipType["SS"] = "SS";
})(LinkRelationshipType = LinkRelationshipType || (LinkRelationshipType = {}));
;
export var LinkRelationshipLabel;
(function (LinkRelationshipLabel) {
    LinkRelationshipLabel["FF"] = "Finish to Start";
    LinkRelationshipLabel["SS"] = "Start to Start";
})(LinkRelationshipLabel = LinkRelationshipLabel || (LinkRelationshipLabel = {}));
export class MetaActivityLink extends Record {
    constructor() {
        super(...arguments);
        this.lag_days = 0;
        this.link_relationship_type = LinkRelationshipType.FS;
    }
}
__decorate([
    Type(() => ActivityLite)
], MetaActivityLink.prototype, "successor_activity", void 0);
__decorate([
    Type(() => ActivityLite)
], MetaActivityLink.prototype, "predecessor_activity", void 0);
export class ActivityLite extends Record {
    constructor() {
        super(...arguments);
        this.name = '';
        this.state = ActivityState.NOT_STARTED;
        this.activity_type = ActivityType.ACTIVITY;
        this.working_days = [];
        this.location_groups = [];
        this.state_timeline = [];
        this.is_finished = false;
        this.is_critical = false;
        this.is_all_day = true;
        this.background_color = undefined;
    }
    get day_start_time() {
        const date = dateUtils.resetTime(new Date());
        date.setMinutes(this.start_time_minutes || 0);
        return date;
    }
    get day_end_time() {
        const date = dateUtils.resetTime(new Date());
        date.setMinutes(this.end_time_minutes || 0);
        return date;
    }
}
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "state_date", void 0);
__decorate([
    Type(() => CompanyLite)
], ActivityLite.prototype, "company", void 0);
__decorate([
    Type(() => UserLite)
], ActivityLite.prototype, "created_by", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "planned_start_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "planned_end_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "relative_planned_end_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "projected_end_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "actual_start_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityLite.prototype, "actual_end_date", void 0);
__decorate([
    Type(() => LocationGroup)
], ActivityLite.prototype, "location_groups", void 0);
__decorate([
    Type(() => TimelineStateLog)
], ActivityLite.prototype, "state_timeline", void 0);
export class Activity extends ActivityLite {
    constructor() {
        super(...arguments);
        this.assigned_users = [];
    }
}
__decorate([
    Type(() => ProjectUserLite)
], Activity.prototype, "assigned_users", void 0);
__decorate([
    Type(() => WorkingCalendar)
], Activity.prototype, "working_calendar", void 0);
;
export class ActivityFull extends Activity {
    constructor() {
        super(...arguments);
        this.comments = [];
        this.links = [];
        // used for delay events
        this.successor_links = [];
    }
}
__decorate([
    Type(() => ScheduleLite)
], ActivityFull.prototype, "schedule", void 0);
__decorate([
    Type(() => Comment)
], ActivityFull.prototype, "comments", void 0);
__decorate([
    Type(() => MetaActivityLink)
], ActivityFull.prototype, "links", void 0);
__decorate([
    Type(() => MetaActivityLink)
], ActivityFull.prototype, "successor_links", void 0);
export class ActivityMeta {
    constructor() {
        this.assigned_project_users = [];
        this.location_groups = [];
    }
}
__decorate([
    Type(() => ProjectUserLite)
], ActivityMeta.prototype, "assigned_project_users", void 0);
__decorate([
    Type(() => CompanyLite)
], ActivityMeta.prototype, "company", void 0);
__decorate([
    Type(() => UserLite)
], ActivityMeta.prototype, "edited_by", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityMeta.prototype, "created_at", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityMeta.prototype, "log_planned_start_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityMeta.prototype, "log_planned_end_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityMeta.prototype, "log_actual_start_date", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityMeta.prototype, "log_actual_end_date", void 0);
__decorate([
    Type(() => LocationGroup)
], ActivityMeta.prototype, "location_groups", void 0);
export class ActivityStateLog {
}
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityStateLog.prototype, "start_date_override", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityStateLog.prototype, "end_date_override", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityStateLog.prototype, "entered_at", void 0);
__decorate([
    Type(() => UserLite)
], ActivityStateLog.prototype, "edited_by", void 0);
__decorate([
    Transform(({ value }) => dateUtils.deserializeDate(value), { toClassOnly: true })
], ActivityStateLog.prototype, "created_at", void 0);
export class ActivityLogResponse {
    constructor() {
        this.metas = [];
        this.state_logs = [];
    }
}
__decorate([
    Type(() => ActivityMeta)
], ActivityLogResponse.prototype, "metas", void 0);
__decorate([
    Type(() => ActivityStateLog)
], ActivityLogResponse.prototype, "state_logs", void 0);
export class ActivitiesGroupedByCompany {
    constructor() {
        this.activities = [];
    }
}
__decorate([
    Type(() => CompanyLite)
], ActivitiesGroupedByCompany.prototype, "company", void 0);
__decorate([
    Type(() => ActivityLite)
], ActivitiesGroupedByCompany.prototype, "activities", void 0);
