import { stringUtils } from './StringUtils.js';
import { ProfilePictureRecord } from "./ProfilePictureRecord.js";
export var AppRole;
(function (AppRole) {
    AppRole["USER"] = "USER";
    AppRole["APP_ADMIN"] = "APP_ADMIN";
    AppRole["SUPER_ADMIN"] = "SUPER ADMIN";
})(AppRole = AppRole || (AppRole = {}));
;
export class UserLite extends ProfilePictureRecord {
    constructor() {
        super(...arguments);
        this.first_name = '';
        this.last_name = '';
        this.position = '';
        this.phone = '';
        this.email = '';
    }
    get fullName() { return `${this.first_name} ${this.last_name}`; }
    ;
    get initials() { return stringUtils.getInitials(this.fullName); }
    ;
}
export class User extends UserLite {
}
