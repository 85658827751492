
import styled from 'styled-components';
import { Theme } from '../../../styles/theme';

type MainLayoutProps = {
  transparent?: boolean;
};

const MainLayout = styled.div<MainLayoutProps>`
  background-color: ${props => props.transparent ? 'transparent' : Theme.colors.gray50};
  padding: 21px;
  height: 100vh;
`;
export default MainLayout;