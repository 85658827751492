import { Day } from './Day.js';
import { Record } from './Record.js';
import { dateUtils } from './DateUtils.js';
export class WorkingCalendar extends Record {
    constructor() {
        super(...arguments);
        this.start_day = Day.MONDAY;
        this.end_day = Day.FRIDAY;
        this.day_start_time_minutes = 60 * 9; //9:00AM
        this.day_end_time_minutes = 60 * 17; //5:00PM
        this.is_default = false;
    }
    get day_start_time() {
        const date = dateUtils.resetTime(new Date());
        date.setMinutes(this.day_start_time_minutes);
        return date;
    }
    get day_end_time() {
        const date = dateUtils.resetTime(new Date());
        date.setMinutes(this.day_end_time_minutes);
        return date;
    }
}
;
