import styled from 'styled-components';
import { ProfilePictureRecord } from '@lookahead/core';
import { Theme } from '../../styles/theme';

type ProfileImageProps = {
  size?: number;
  style?: any;
  record?: ProfilePictureRecord;
  imageOverride?: string;
} & typeof defaultProps;

const defaultProps = {
  size: 32
};

const Container = styled.div<ProfileImageProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;
const DefaultImageContainer = styled.div<ProfileImageProps>`
  display: flex;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  background-color: ${props => props.record?.background_color};
  justify-content: center;
  align-items: center;
`
const DefaultImageText = styled.div<{size: number, color: string}>`
  font-size: ${props => {
    if (props.size >= 32) return Theme.fontSize.med2;
    return Theme.fontSize.tiny2;
  }};
  font-weight: bold;
  color: ${props => props.color};
  letterSpacing: -1px;
  text-align: center;
`;
const DefaultImage = (props: ProfileImageProps) => (
  <DefaultImageContainer {...props}>
    <DefaultImageText color={Theme.colors.white} size={props.size}>{props.record?.initials}</DefaultImageText>
  </DefaultImageContainer>
);

const ProfileImage = (props: ProfileImageProps) => {
  return (
    <Container {...props}>
      {(!props.imageOverride || props.imageOverride?.length === 0) ?
        (props.record?.profile_picture ?
          <img src={props.record.profile_picture} alt='profile' style={{width: props.size, height: props.size, borderRadius: props.size / 2}} /> :
          <DefaultImage {...props} style={null} />) :
        <img src={props.imageOverride} alt='profile' style={{width: props.size, height: props.size, borderRadius: props.size / 2}} />
      }
    </Container>
  )
};
ProfileImage.defaultProps = defaultProps;
export default ProfileImage;