import styled from "styled-components";
import { SizeProps } from "../../styles/propTypes";
import { fontBySize } from "../../styles/styleSnippets";
import { Theme } from "../../styles/theme";

type AppTextProps = {
  color?: string;
  style?: any;
  bold?: boolean;
} & SizeProps;

const AppText = styled.div<AppTextProps>`
  font-size: ${props => fontBySize(props)};
  color: ${props => props.color ? props.color : Theme.colors.black};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
`;
export default AppText;