const ImageType = {
    'jpg': true,
    'jpeg': true,
    'png': true,
    'gif': true,
    'svg': true
};
export const uploadFileUtils = {
    isBlob: (obj) => obj instanceof Blob,
    isValidUploadFile: (obj) => {
        return obj.uri && obj.name && obj.type;
    },
    getRequestParams: (file) => ({
        uri: file.uri,
        name: file.name,
        type: file.type
    })
};
export class UploadFile {
    get type() {
        if (!this._type) {
            return '';
        }
        if (ImageType[this._type.toLowerCase()]) {
            return `image/${this._type}`;
        }
        return this._type;
    }
    constructor(uri, name) {
        this.uri = uri;
        const fileName = this.uri.split('/').pop();
        this.name = name || fileName;
        this._type = fileName === null || fileName === void 0 ? void 0 : fileName.split('.').pop();
    }
}
