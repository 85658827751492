import styled from "styled-components";

type RowProps = {
  justifyContent?: string;
  alignItems?: string;
}

export default styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${props => props.justifyContent && `align-items: ${props.alignItems}`};
`;