import { Record } from "./Record.js";
export var ScheduleAccessLevel;
(function (ScheduleAccessLevel) {
    ScheduleAccessLevel["PRIVATE"] = "PRIVATE";
    ScheduleAccessLevel["SHARED"] = "SHARED";
    ScheduleAccessLevel["PUBLISHED"] = "PUBLISHED";
})(ScheduleAccessLevel = ScheduleAccessLevel || (ScheduleAccessLevel = {}));
;
export const scheduleUtils = {
    group_by_access_level: (schedules) => {
        const map = new Map();
        schedules.forEach(s => {
            map.set(s.schedule_access_level, [...map.get(s.schedule_access_level) || [], s]);
        });
        return map;
    },
    is_owner: (schedule, userId) => userId !== undefined && (schedule === null || schedule === void 0 ? void 0 : schedule.created_by_id) === userId,
    is_published: (schedule) => (schedule === null || schedule === void 0 ? void 0 : schedule.schedule_access_level) === ScheduleAccessLevel.PUBLISHED,
};
export class ScheduleLite extends Record {
    constructor() {
        super(...arguments);
        this.name = '';
        this.schedule_access_level = ScheduleAccessLevel.PRIVATE;
    }
}
export class Schedule extends ScheduleLite {
}
;
