export var TimeZone;
(function (TimeZone) {
    TimeZone["Etc/GMT+12"] = "Etc/GMT+12";
    TimeZone["Pacific/Midway"] = "Pacific/Midway";
    TimeZone["Pacific/Pago_Pago"] = "Pacific/Pago_Pago";
    TimeZone["Pacific/Honolulu"] = "Pacific/Honolulu";
    TimeZone["America/Juneau"] = "America/Juneau";
    TimeZone["America/Los_Angeles"] = "America/Los_Angeles";
    TimeZone["America/Tijuana"] = "America/Tijuana";
    TimeZone["America/Denver"] = "America/Denver";
    TimeZone["America/Phoenix"] = "America/Phoenix";
    TimeZone["America/Chihuahua"] = "America/Chihuahua";
    TimeZone["America/Mazatlan"] = "America/Mazatlan";
    TimeZone["America/Chicago"] = "America/Chicago";
    TimeZone["America/Regina"] = "America/Regina";
    TimeZone["America/Mexico_City"] = "America/Mexico_City";
    TimeZone["America/Monterrey"] = "America/Monterrey";
    TimeZone["America/Guatemala"] = "America/Guatemala";
    TimeZone["America/New_York"] = "America/New_York";
    TimeZone["America/Indiana/Indianapolis"] = "America/Indiana/Indianapolis";
    TimeZone["America/Bogota"] = "America/Bogota";
    TimeZone["America/Lima"] = "America/Lima";
    TimeZone["America/Halifax"] = "America/Halifax";
    TimeZone["America/Caracas"] = "America/Caracas";
    TimeZone["America/La_Paz"] = "America/La_Paz";
    TimeZone["America/Santiago"] = "America/Santiago";
    TimeZone["America/St_Johns"] = "America/St_Johns";
    TimeZone["America/Sao_Paulo"] = "America/Sao_Paulo";
    TimeZone["America/Argentina/Buenos_Aires"] = "America/Argentina/Buenos_Aires";
    TimeZone["America/Montevideo"] = "America/Montevideo";
    TimeZone["America/Guyana"] = "America/Guyana";
    TimeZone["America/Puerto_Rico"] = "America/Puerto_Rico";
    TimeZone["America/Godthab"] = "America/Godthab";
    TimeZone["Atlantic/South_Georgia"] = "Atlantic/South_Georgia";
    TimeZone["Atlantic/Azores"] = "Atlantic/Azores";
    TimeZone["Atlantic/Cape_Verde"] = "Atlantic/Cape_Verde";
    TimeZone["Europe/Dublin"] = "Europe/Dublin";
    TimeZone["Europe/London"] = "Europe/London";
    TimeZone["Europe/Lisbon"] = "Europe/Lisbon";
    TimeZone["Africa/Casablanca"] = "Africa/Casablanca";
    TimeZone["Africa/Monrovia"] = "Africa/Monrovia";
    TimeZone["Etc/UTC"] = "Etc/UTC";
    TimeZone["Europe/Belgrade"] = "Europe/Belgrade";
    TimeZone["Europe/Bratislava"] = "Europe/Bratislava";
    TimeZone["Europe/Budapest"] = "Europe/Budapest";
    TimeZone["Europe/Ljubljana"] = "Europe/Ljubljana";
    TimeZone["Europe/Prague"] = "Europe/Prague";
    TimeZone["Europe/Sarajevo"] = "Europe/Sarajevo";
    TimeZone["Europe/Skopje"] = "Europe/Skopje";
    TimeZone["Europe/Warsaw"] = "Europe/Warsaw";
    TimeZone["Europe/Zagreb"] = "Europe/Zagreb";
    TimeZone["Europe/Brussels"] = "Europe/Brussels";
    TimeZone["Europe/Copenhagen"] = "Europe/Copenhagen";
    TimeZone["Europe/Madrid"] = "Europe/Madrid";
    TimeZone["Europe/Paris"] = "Europe/Paris";
    TimeZone["Europe/Amsterdam"] = "Europe/Amsterdam";
    TimeZone["Europe/Berlin"] = "Europe/Berlin";
    TimeZone["Europe/Zurich"] = "Europe/Zurich";
    TimeZone["Europe/Rome"] = "Europe/Rome";
    TimeZone["Europe/Stockholm"] = "Europe/Stockholm";
    TimeZone["Europe/Vienna"] = "Europe/Vienna";
    TimeZone["Africa/Algiers"] = "Africa/Algiers";
    TimeZone["Europe/Bucharest"] = "Europe/Bucharest";
    TimeZone["Africa/Cairo"] = "Africa/Cairo";
    TimeZone["Europe/Helsinki"] = "Europe/Helsinki";
    TimeZone["Europe/Kiev"] = "Europe/Kiev";
    TimeZone["Europe/Riga"] = "Europe/Riga";
    TimeZone["Europe/Sofia"] = "Europe/Sofia";
    TimeZone["Europe/Tallinn"] = "Europe/Tallinn";
    TimeZone["Europe/Vilnius"] = "Europe/Vilnius";
    TimeZone["Europe/Athens"] = "Europe/Athens";
    TimeZone["Europe/Istanbul"] = "Europe/Istanbul";
    TimeZone["Europe/Minsk"] = "Europe/Minsk";
    TimeZone["Asia/Jerusalem"] = "Asia/Jerusalem";
    TimeZone["Africa/Harare"] = "Africa/Harare";
    TimeZone["Africa/Johannesburg"] = "Africa/Johannesburg";
    TimeZone["Europe/Kaliningrad"] = "Europe/Kaliningrad";
    TimeZone["Europe/Moscow"] = "Europe/Moscow";
    TimeZone["Europe/Volgograd"] = "Europe/Volgograd";
    TimeZone["Europe/Samara"] = "Europe/Samara";
    TimeZone["Asia/Kuwait"] = "Asia/Kuwait";
    TimeZone["Asia/Riyadh"] = "Asia/Riyadh";
    TimeZone["Africa/Nairobi"] = "Africa/Nairobi";
    TimeZone["Asia/Baghdad"] = "Asia/Baghdad";
    TimeZone["Asia/Tehran"] = "Asia/Tehran";
    TimeZone["Asia/Muscat"] = "Asia/Muscat";
    TimeZone["Asia/Baku"] = "Asia/Baku";
    TimeZone["Asia/Tbilisi"] = "Asia/Tbilisi";
    TimeZone["Asia/Yerevan"] = "Asia/Yerevan";
    TimeZone["Asia/Kabul"] = "Asia/Kabul";
    TimeZone["Asia/Yekaterinburg"] = "Asia/Yekaterinburg";
    TimeZone["Asia/Karachi"] = "Asia/Karachi";
    TimeZone["Asia/Tashkent"] = "Asia/Tashkent";
    TimeZone["Asia/Kolkata"] = "Asia/Kolkata";
    TimeZone["Asia/Kathmandu"] = "Asia/Kathmandu";
    TimeZone["Asia/Dhaka"] = "Asia/Dhaka";
    TimeZone["Asia/Colombo"] = "Asia/Colombo";
    TimeZone["Asia/Almaty"] = "Asia/Almaty";
    TimeZone["Asia/Novosibirsk"] = "Asia/Novosibirsk";
    TimeZone["Asia/Rangoon"] = "Asia/Rangoon";
    TimeZone["Asia/Bangkok"] = "Asia/Bangkok";
    TimeZone["Asia/Jakarta"] = "Asia/Jakarta";
    TimeZone["Asia/Krasnoyarsk"] = "Asia/Krasnoyarsk";
    TimeZone["Asia/Shanghai"] = "Asia/Shanghai";
    TimeZone["Asia/Chongqing"] = "Asia/Chongqing";
    TimeZone["Asia/Hong_Kong"] = "Asia/Hong_Kong";
    TimeZone["Asia/Urumqi"] = "Asia/Urumqi";
    TimeZone["Asia/Kuala_Lumpur"] = "Asia/Kuala_Lumpur";
    TimeZone["Asia/Singapore"] = "Asia/Singapore";
    TimeZone["Asia/Taipei"] = "Asia/Taipei";
    TimeZone["Australia/Perth"] = "Australia/Perth";
    TimeZone["Asia/Irkutsk"] = "Asia/Irkutsk";
    TimeZone["Asia/Ulaanbaatar"] = "Asia/Ulaanbaatar";
    TimeZone["Asia/Seoul"] = "Asia/Seoul";
    TimeZone["Asia/Tokyo"] = "Asia/Tokyo";
    TimeZone["Asia/Yakutsk"] = "Asia/Yakutsk";
    TimeZone["Australia/Darwin"] = "Australia/Darwin";
    TimeZone["Australia/Adelaide"] = "Australia/Adelaide";
    TimeZone["Australia/Melbourne"] = "Australia/Melbourne";
    TimeZone["Australia/Sydney"] = "Australia/Sydney";
    TimeZone["Australia/Brisbane"] = "Australia/Brisbane";
    TimeZone["Australia/Hobart"] = "Australia/Hobart";
    TimeZone["Asia/Vladivostok"] = "Asia/Vladivostok";
    TimeZone["Pacific/Guam"] = "Pacific/Guam";
    TimeZone["Pacific/Port_Moresby"] = "Pacific/Port_Moresby";
    TimeZone["Asia/Magadan"] = "Asia/Magadan";
    TimeZone["Asia/Srednekolymsk"] = "Asia/Srednekolymsk";
    TimeZone["Pacific/Guadalcanal"] = "Pacific/Guadalcanal";
    TimeZone["Pacific/Noumea"] = "Pacific/Noumea";
    TimeZone["Pacific/Fiji"] = "Pacific/Fiji";
    TimeZone["Asia/Kamchatka"] = "Asia/Kamchatka";
    TimeZone["Pacific/Majuro"] = "Pacific/Majuro";
    TimeZone["Pacific/Auckland"] = "Pacific/Auckland";
    TimeZone["Pacific/Tongatapu"] = "Pacific/Tongatapu";
    TimeZone["Pacific/Fakaofo"] = "Pacific/Fakaofo";
    TimeZone["Pacific/Chatham"] = "Pacific/Chatham";
    TimeZone["Pacific/Apia"] = "Pacific/Apia";
})(TimeZone = TimeZone || (TimeZone = {}));
;
export const USATimeZones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Phoenix',
    'America/Los_Angeles'
];
export const TimeZoneLabel = {
    'America/New_York': 'EDT (Eastern Daylight Time)',
    'America/Chicago': 'CDT (Central Daylight Time)',
    'America/Denver': 'MDT (Mountain Daylight Time)',
    'America/Phoenix': 'MST (Mountain Standard Time)',
    'America/Los_Angeles': 'PDT (Pacific Daylight Time)'
};
