var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Type } from "class-transformer";
import { ActivityLite } from "../Activity.js";
import { CompanyLite } from "../Company.js";
import { DelayDate } from "../DelayDate.js";
export class TimeCount {
}
export class InsightsReport {
    get is_late() {
        return this.critical_path_delta ? this.critical_path_delta > 0 : false;
    }
}
__decorate([
    Type(() => CompanyLite)
], InsightsReport.prototype, "company", void 0);
__decorate([
    Type(() => TimeCount)
], InsightsReport.prototype, "duration_time_count", void 0);
__decorate([
    Type(() => TimeCount)
], InsightsReport.prototype, "start_time_count", void 0);
export var ReportActivityType;
(function (ReportActivityType) {
    ReportActivityType["DURATION"] = "DURATION";
    ReportActivityType["START"] = "START";
})(ReportActivityType = ReportActivityType || (ReportActivityType = {}));
;
export var ReportActivityStatus;
(function (ReportActivityStatus) {
    ReportActivityStatus["EARLY"] = "EARLY";
    ReportActivityStatus["LATE"] = "LATE";
    ReportActivityStatus["ON_TIME"] = "ON_TIME";
})(ReportActivityStatus = ReportActivityStatus || (ReportActivityStatus = {}));
;
export class ReportActivityLite extends ActivityLite {
}
export class ReportDelayDate extends DelayDate {
}
