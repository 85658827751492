import { startWith, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { interval, map } from 'rxjs';
import { ApiService } from './api-base.js';
import { Notification } from '../../models/Notification.js';
export const NotificationService = {
    getNotifications: (projectId, onlyNew = false) => ApiService.get(`/projects/${projectId}/notifications${onlyNew ? '/new' : ''}`)
        .pipe(map((response) => plainToInstance(Notification, response))),
    getNotificationCount: (projectId) => ApiService.get(`/projects/${projectId}/notifications/count`, { showLogs: false })
        .pipe(map((response) => response === null || response === void 0 ? void 0 : response.count) || 0),
    pollNotificationCount: (projectId, every = 5000) => interval(every).pipe(startWith(0), switchMap(() => NotificationService.getNotificationCount(projectId))),
    markAsSeen: (projectId, notificationId) => ApiService.put(`/projects/${projectId}/notifications/${notificationId}/mark_seen`, {}),
    markAsSeenCurrentUser: (projectId) => ApiService.put(`/projects/${projectId}/current_user/notifications/mark_seen`, {}),
    createTestPushNotification: (projectId) => ApiService.put(`/projects/${projectId}/push-notification-debug`, {}),
};
