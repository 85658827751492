const colorHexToNumberArray = (hex) => {
    const arr = hex.split('');
    if (arr[0] !== '#' || arr.length !== 7) {
        return null;
    }
    return [
        Number(`0x${arr[1]}${arr[2]}`),
        Number(`0x${arr[3]}${arr[4]}`),
        Number(`0x${arr[5]}${arr[6]}`)
    ];
};
const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
//src: https://stackoverflow.com/a/47024391
const getHexWithOpacity = (colorHex, opacity, backgroundHex = '#ffffff') => {
    if (colorHex === undefined) {
        return backgroundHex;
    }
    const a = opacity;
    const hexNumbers = colorHexToNumberArray(colorHex);
    const backgroundNumbers = colorHexToNumberArray(backgroundHex);
    if (hexNumbers === null) {
        return colorHex;
    }
    if (backgroundNumbers === null) {
        return colorHex;
    }
    const r = Math.floor(hexNumbers[0] * a + backgroundNumbers[0] * (1 - a));
    const g = Math.floor(hexNumbers[1] * a + backgroundNumbers[1] * (1 - a));
    const b = Math.floor(hexNumbers[2] * a + backgroundNumbers[2] * (1 - a));
    return "#" + (r << 16 | g << 8 | b).toString(16);
};
const getContrastTextColor = (backgroundHex, darkColor, lightColor) => {
    if (backgroundHex === undefined) {
        return darkColor;
    }
    const rgb = hexToRgb(backgroundHex);
    const threshold = 186;
    if (rgb === null) {
        return darkColor;
    }
    const useDarkColor = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > threshold;
    return useDarkColor ? darkColor : lightColor;
};
export const colorUtils = {
    getHexWithOpacity,
    getContrastTextColor
};
