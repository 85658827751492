import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { Invite } from '../../models/Invite.js';
import { ApiService } from './api-base.js';
export const InviteService = {
    getInvite: (token) => ApiService.get(`/invite/${token}`)
        .pipe(map((response) => plainToInstance(Invite, response))),
    acceptInvite: (token, firstName, lastName, position, password, phone, email) => ApiService.put(`/invite/accept/${token}`, {
        first_name: firstName,
        last_name: lastName,
        position,
        password,
        phone,
        email
    }),
};
